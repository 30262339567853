import React, { Component } from 'react';
import * as Colyseus from "colyseus.js";
import Lottie from 'react-lottie';
import { Howl, Howler } from "howler";

import getAvatarById from "constants/avatars";

import "animate.css";
import styles from 'components/MathsMaster/MathsTutorialStyles.module.scss';

import GoalBg from "images/MathsMaster/big-number-banner.png";

var audio = {

};

export default class Tutorial extends Component {
    static displayName = Tutorial.name;

    constructor(props) {
        super(props);

        this.client = new Colyseus.Client(process.env.REACT_APP_GAME_SERVER_URL);
        this.state = {
            showStepOne: true,
            showStepOneTextOne: false,
            showStepOneTextTwo: false,
            stepOnePlayers: [
                {
                    avatar: 2,
                    show: false,
                },
                {
                    avatar: 5,
                    show: false,
                },
                {
                    avatar: 8,
                    show: false,
                },
                {
                    avatar: 9,
                    show: false,
                }
            ],

            showStepTwo: false,
            showStepTwoTextOne: false,
            showStepTwoTextTwo: false,
            showStepTwoNumberGoal: false,
            showStepTwoNumbers: [],
            stepTwoNumbers: [100, 50, 3, 5, 8, 2],
            stepTwoNumberGoal: 588,

            showStepThree: false,
            showStepThreeText: false,
            stepThreePlayer: {
                name: "DINOTATO",
                avatar: 1,
                mathsData: {
                    equationHistory: [
                        "50 - 8 = 42",
                        "8 + 6 = 14",
                        "42 * 14 = 588"
                    ],
                    submittedNumber: 588,
                }
            },
            showStepThreePlayer: false,

            showStepFour: false,
            showStepFourTextOne: false,
            showStepFourTextTwo: false,

            showStepFive: false,
            showStepFiveText: false,
        };
        this.snatcherAnimRef = React.createRef();

    }

    componentDidMount() {
        //this.initAudio();
        this.beginTutorial();
    }

    initAudio() {
        this.preloadAudio();
        Howler.volume(0.5);
    }

    preloadAudio() {
        for (let key in audio) {
            audio[key].loaded = new Howl({
                src: [audio[key].import],
                preload: true,
                loop: audio[key].loop,
                volume: audio[key].volume
            });
        }
    }

    playAudio(audio) {
        if (audio.loaded) audio.loaded.play();
    }

    getRandomElement(array) {
        return array[Math.random() * array.length >> 0];
    }

    beginTutorial() {
        setTimeout(() => {
            this.doStepOne();
        }, 2500);
    }

    sleep(time) {
        return new Promise((resolve) => setTimeout(resolve, time));
    }

    async doStepOne() {
        this.setState({ showStepOne: true, showStepOneTextOne: true, });
        await this.sleep(1000);
        let stepOnePlayers = [...this.state.stepOnePlayers];
        stepOnePlayers.forEach(async (x, i) => {
            await this.sleep(200);
            stepOnePlayers[i].show = true;
            this.setState({ stepOnePlayers, });
        });

        await this.sleep(2000);
        this.setState({ showStepOneTextTwo: true });

        await this.sleep(5000);
        this.setState({ showStepOne: false, });
        await this.sleep(1000);
        this.doStepTwo();
    }

    async doStepTwo() {
        this.setState({ showStepTwo: true, });
        await this.sleep(1000);
        this.setState({ showStepTwoTextOne: true, });

        await this.sleep(1500);

        let showStepTwoNumbers = [...this.state.showStepTwoNumbers];
        for (let i = 0; i < this.state.stepTwoNumbers.length; i++) {
            await this.sleep(250);
            showStepTwoNumbers[i] = true;
            this.setState({ showStepTwoNumbers });
        }
        await this.sleep(2000);
        this.setState({ showStepTwoNumberGoal: true, showStepTwoTextTwo: true, });
        await this.sleep(5000);
        this.setState({ showStepTwo: false, });
        await this.sleep(1000);
        this.doStepThree();
    }

    async doStepThree() {
        this.setState({ showStepThree: true, });
        await this.sleep(1000);

        this.setState({ showStepThreeText: true, });

        await this.sleep(1500);
        this.setState({ showStepThreePlayer: true, });

        await this.sleep(5000);
        this.setState({ showStepThree: false, });
        await this.sleep(1000);
        this.doStepFour();
    }

    async doStepFour() {
        this.setState({ showStepFour: true, });

        await this.sleep(1000);

        this.setState({ showStepFourTextOne: true, });

        await this.sleep(1500);
        this.setState({ showStepFourTextTwo: true, });

        await this.sleep(5000);
        this.setState({ showStepFour: false, });
        await this.sleep(1000);
        this.doStepFive();
    }

    async doStepFive() {
        this.setState({ showStepFive: true, });
        await this.sleep(1000);
        this.setState({ showStepFiveText: true, });

        await this.sleep(5000);
        this.setState({ showStepFive: false, });
        this.props.room.send("end_tutorial", {});
    }

    getVoteCount() {
        let count = 0;
        this.props.players.forEach((x) => {
            if (x.votedSkip) count++;
        });
        return count;
    }

    render() {
        return (
            <div id="tutorialContainer" className={styles.tutorialContainer}>
                <div className={styles.voteCount}>Skip Votes: {this.getVoteCount()} / {this.props.players.length}</div>
                <div className={`${styles.stepOne} ${styles.step} ${this.state.showStepOne && styles.show}`}>
                    <div className={`${styles.text} ${this.state.showStepOneTextOne && styles.show}`}>Get ready...</div>
                    <div className={styles.playerRow}>
                        {
                            this.state.stepOnePlayers.map((x) => {
                                return <div className={`${styles.player} ${x.show && styles.show}`}>
                                    <Lottie
                                        options={getAvatarById(x.avatar).idleAnim}
                                        width="100%"
                                        height="100%"
                                        isClickToPauseDisabled={true}
                                    />
                                </div>
                            })
                        }
                    </div>
                    <div className={`${styles.text} ${this.state.showStepOneTextTwo && styles.show}`}>...It's time to play 'Maths Master'</div>
                </div>
                <div className={`${styles.stepTwo} ${styles.step} ${this.state.showStepTwo && styles.show}`} style={{ width: "100%" }}>
                    <div className={`${styles.text} ${this.state.showStepTwoTextOne && styles.show}`}>You will be given 6 Numbers...</div>
                    <div className={`${styles.numbersSection}`}>
                        <div className={`${styles.goalSection} ${this.state.showStepTwoNumberGoal && styles.show}`}>
                            <img className={styles.goalBg} src={GoalBg} alt="goal-bg" />
                            <div className={styles.goalText}>{this.state.stepTwoNumberGoal}</div>
                        </div>
                        <div className={`${styles.numbersGroup} ${styles.show}`}>
                            <div className={styles.numbersRow}>
                                {
                                    this.state.stepTwoNumbers.map((x, i) => {
                                        return <div className={`${styles.number} ${this.state.showStepTwoNumbers[i] && styles.show}`}>
                                            <p className={styles.numberText} >{x}</p>
                                        </div>
                                    })
                                }
                            </div>
                        </div>
                    </div>
                    <div className={`${styles.text} ${this.state.showStepTwoTextTwo && styles.show}`}>...and a Number Goal!</div>
                </div>
                <div className={`${styles.stepThree} ${styles.step} ${this.state.showStepThree && styles.show}`}>
                    <div className={`${styles.text} ${this.state.showStepThreeText && styles.show}`}>Get as close to the goal as possible!</div>
                    <div className={`${styles.closestAnswerSection}`}>
                        <div className={`${styles.potato} ${!this.state.showStepThreePlayer && styles.hide}`}>
                            <div className={`${styles.nameBox}`}>
                                <div className={`${styles.name}`}>
                                    {this.state.stepThreePlayer.name}
                                </div>
                            </div>
                            <Lottie
                                options={getAvatarById(this.state.stepThreePlayer.avatar).idleAnim}
                                width="100%"
                                height="100%"
                                isClickToPauseDisabled={true}
                            />
                        </div >
                        <div className={`${styles.answer} ${this.state.showStepThreePlayer && styles.show}`} >
                            {
                                this.state.stepThreePlayer.mathsData.equationHistory.map((value, index) => {
                                    return <p className={styles.equation} style={{ "--delay": (index / 3) + "s" }}>{value}</p>;
                                })
                            }
                            <p className={`${styles.playerAnswer} ${styles.equation}`} style={{ "--delay": (this.state.stepThreePlayer.mathsData.equationHistory.length / 3) + "s" }} >{this.state.stepThreePlayer.mathsData.submittedNumber}</p>
                        </div>
                    </div>
                </div>
                <div className={`${styles.stepFour} ${styles.step} ${this.state.showStepFour && styles.show}`}>
                    <div className={`${styles.text} ${this.state.showStepFourTextOne && styles.show}`}>You get 10 points for the closest number</div>
                    <div className={`${styles.text} ${this.state.showStepFourTextTwo && styles.show}`}>and 5 points for second closest!</div>
                </div>
                <div className={`${styles.stepFive} ${styles.step} ${this.state.showStepFive && styles.show}`}>
                    <div className={`${styles.text} ${styles.larger} ${this.state.showStepFiveText && styles.show}`}>GOOD LUCK!</div>
                </div>
            </div>
        );
    }
}