
//Anagram Magic
import AM_WordTileImg from "images/AnagramMagic/word-tile.png";
import AM_GoldWordTileImg from "images/AnagramMagic/word-tile-gold.png";
import AM_logo from "images/Host/anagram-magic-shop-logo.png";
import AM_mutedIcon from "images/Host/lobby_muted.png";
import AM_unmutedIcon from "images/Host/lobby_unmuted.png";
import AM_fullscreenIcon from "images/Host/lobby_fullscreen.png";
import AM_helpIcon from "images/Host/lobby_help.png";
import AM_ButtonImg1 from "images/AnagramMagic/button-1.png";
import AM_ButtonImg2 from "images/AnagramMagic/button-2.png";
import AM_RaysImg from "images/AnagramMagic/rays.png";
import AM_Round1Img from "images/AnagramMagic/round-1-text.png";
import AM_Round2Img from "images/AnagramMagic/round-2-text.png";
import AM_Round3Img from "images/AnagramMagic/round-3-text.png";
import AM_WinnerBanner from "images/AnagramMagic/winner-banner.png";
import AM_GameOverBanner from "images/AnagramMagic/game-over-banner.png";
import AM_WinnersTitle from "images/AnagramMagic/winners-banner.png";
import AM_LongestWordsTitle from "images/AnagramMagic/longest-words-banner.png";
import AM_WordBanner from "images/AnagramMagic/word-banner.png";
import AM_EndGameBox from "images/AnagramMagic/end-game-box.png";
import AM_ClockImg from "images/AnagramMagic/clock.png";
import AM_ClockHandImg from "images/AnagramMagic/clock-hand.png";
import AM_ClockBg from "images/AnagramMagic/clock-bg.png";
import AM_StarImg from "images/MathsMaster/star.png";
import AM_CrossImg from "images/MathsMaster/cross.png";


//Maths Master
import MM_TileImg from "images/MathsMaster/square-tile.png";
import MM_GoalBg from "images/MathsMaster/big-number-banner.png";
import MM_NumbersBg from "images/MathsMaster/number-tile-group.png";
import MM_ClockImg from "images/MathsMaster/clock.png";
import MM_ClockHandImg from "images/MathsMaster/clock-hand.png";
import MM_ClockBg from "images/MathsMaster/clock-bg.png";
import MM_logo from "images/Host/maths-master-shop-logo.png";
import MM_mutedIcon from "images/Host/lobby_muted.png";
import MM_unmutedIcon from "images/Host/lobby_unmuted.png";
import MM_fullscreenIcon from "images/Host/lobby_fullscreen.png";
import MM_helpIcon from "images/Host/lobby_help.png";
import MM_ButtonImg1 from "images/MathsMaster/button-1.png";
import MM_ButtonImg2 from "images/MathsMaster/button-2.png";
import MM_RaysImg from "images/MathsMaster/rays.png";
import MM_Round1Img from "images/MathsMaster/round-1-text.png";
import MM_Round2Img from "images/MathsMaster/round-2-text.png";
import MM_Round3Img from "images/MathsMaster/round-3-text.png";
import MM_WinnerBanner from "images/MathsMaster/winner-banner.png";
import MM_WinnersTitle from "images/MathsMaster/winners-banner.png";
import MM_StarImg from "images/MathsMaster/star.png";
import MM_CrossImg from "images/MathsMaster/cross.png";


//Word Wheel
import WW_WheelBg from "images/WordWheel/wheel.png";
import WW_CrossImg from "images/WordWheel/cross.png";
import WW_StarImg from "images/WordWheel/star.png";
import WW_logo from "images/Host/word-wheel-shop-logo.png";
import WW_mutedIcon from "images/Host/lobby_muted.png";
import WW_unmutedIcon from "images/Host/lobby_unmuted.png";
import WW_fullscreenIcon from "images/Host/lobby_fullscreen.png";
import WW_helpIcon from "images/Host/lobby_help.png";
import WW_ButtonImg1 from "images/WordWheel/button-1.png";
import WW_ButtonImg2 from "images/WordWheel/button-2.png";
import WW_RaysImg from "images/WordWheel/rays.png";
import WW_Round1Img from "images/WordWheel/round-1-text.png";
import WW_Round2Img from "images/WordWheel/round-2-text.png";
import WW_Round3Img from "images/WordWheel/round-3-text.png";
import WW_WinnerBanner from "images/WordWheel/winner-banner.png";
import WW_WinnersTitle from "images/WordWheel/winners-banner.png";
import WW_ClockImg from "images/WordWheel/clock.png";
import WW_ClockHandImg from "images/WordWheel/clock-hand.png";
import WW_ClockBg from "images/WordWheel/clock-bg.png";
import WW_BarFront from "images/WordWheel/bar-front.png";


const anagramImages = [
    AM_WordTileImg,
    AM_logo,
    AM_GoldWordTileImg,
    AM_ButtonImg2,
    AM_mutedIcon,
    AM_unmutedIcon,
    AM_fullscreenIcon,
    AM_helpIcon,
    AM_ButtonImg1,
    AM_RaysImg,
    AM_Round1Img,
    AM_Round2Img,
    AM_Round3Img,
    AM_WinnerBanner,
    AM_GameOverBanner,
    AM_WinnersTitle,
    AM_LongestWordsTitle,
    AM_WordBanner,
    AM_EndGameBox,
    AM_ClockImg,
    AM_ClockHandImg,
    AM_ClockBg,
    AM_StarImg,
    AM_CrossImg,
];

const mathsImages = [
    MM_TileImg,
    MM_GoalBg,
    MM_NumbersBg,
    MM_ClockImg,
    MM_ClockHandImg,
    MM_ClockBg,
    MM_logo,
    MM_mutedIcon,
    MM_unmutedIcon,
    MM_fullscreenIcon,
    MM_helpIcon,
    MM_ButtonImg1,
    MM_ButtonImg2,
    MM_RaysImg,
    MM_Round1Img,
    MM_Round2Img,
    MM_Round3Img,
    MM_WinnerBanner,
    MM_WinnersTitle,
    MM_StarImg,
    MM_CrossImg,
];

const wordImages = [
    WW_WheelBg,
    WW_CrossImg,
    WW_StarImg,
    WW_logo,
    WW_mutedIcon,
    WW_unmutedIcon,
    WW_fullscreenIcon,
    WW_helpIcon,
    WW_ButtonImg1,
    WW_ButtonImg2,
    WW_RaysImg,
    WW_Round1Img,
    WW_Round2Img,
    WW_Round3Img,
    WW_WinnerBanner,
    WW_WinnersTitle,
    WW_ClockImg,
    WW_ClockHandImg,
    WW_ClockBg,
    WW_BarFront,
];

const fonts = [
    { font: "Lalezar", path: `https://${window.location.host}/Assets/Lalezar-Regular.ttf` },
    { font: "UbuntuMonoBold", path: `https://${window.location.host}/Assets/UbuntuMono-Bold.ttf` },
];


const WSErrorCodes = {
    1001: "Browser Tab Closing",
    1002: "Malformed framce received",
    1003: "Unsupported frame received",
    1006: "GS ungraceful shutdown / connection refused",
    1011: "Internal Error",
    1012: "Server Restarting",
    1013: "Try again later, server capacity full",
    1014: "Bad Gateway",
    4000: "Gameserver graceful shutdown",
    4050: "Player removed"
};

export default {
    anagramImages,
    mathsImages,
    wordImages,
    fonts,
    WSErrorCodes
};