import React, { Component } from 'react';
import * as Colyseus from "colyseus.js";
import Lottie from 'react-lottie';
import { Howl, Howler } from "howler";

import getAvatarById from "constants/avatars";

import "animate.css";
import styles from 'components/AnagramMagic/AnagramTutorialStyles.module.scss';

import WordTileImg from "images/AnagramMagic/word-tile.png";
import StepThreeBubble from "images/AnagramMagic/tutorial-step-three-img.png";
import StepFourBubble from "images/AnagramMagic/tutorial-step-four-img.png";

var audio = {

};

export default class Tutorial extends Component {
    static displayName = Tutorial.name;

    constructor(props) {
        super(props);

        this.client = new Colyseus.Client(process.env.REACT_APP_GAME_SERVER_URL);
        this.state = {
            showStepOne: true,
            showStepOneTextOne: false,
            showStepOneTextTwo: false,
            stepOnePlayers: [
                {
                    avatar: 2,
                    show: false,
                },
                {
                    avatar: 5,
                    show: false,
                },
                {
                    avatar: 8,
                    show: false,
                },
                {
                    avatar: 9,
                    show: false,
                }
            ],

            showStepTwo: false,
            showStepTwoText: false,
            stepTwoAnagram: "RSGAUEITP",
            showStepTwoLetters: [],

            showStepThree: false,
            showStepThreeTextOne: false,
            showStepThreeTextTwo: false,
            showStepThreeBubble: false,

            showStepFour: false,
            showStepFourText: false,
            showStepFourBubble: false,

            showStepFive: false,
            showStepFiveText: false,
            showStepFiveTitle: false,

            showStepSix: false,
            showStepSixText: false,
            stepSixAnagram: "ECOHCOATL",
            stepSixAnswer: "CHOCOLATE",
            showStepSixLetters: [],
            showStepSixAnswer: false,

            showStepSeven: false,
            showStepSevenPodium: false,
            stepSevenPlayers: [
                {
                    name: "1st",
                    avatar: 2,
                },
                {
                    name: "2nd",
                    avatar: 5,
                },
                {
                    name: "3rd",
                    avatar: 8,
                },
            ],

            showStepEight: false,
            showStepEightText: false,
        };
        this.snatcherAnimRef = React.createRef();

    }

    componentDidMount() {
        //this.initAudio();
        this.beginTutorial();
    }

    initAudio() {
        this.preloadAudio();
        Howler.volume(0.5);
    }

    preloadAudio() {
        for (let key in audio) {
            audio[key].loaded = new Howl({
                src: [audio[key].import],
                preload: true,
                loop: audio[key].loop,
                volume: audio[key].volume
            });
        }
    }

    playAudio(audio) {
        if (audio.loaded) audio.loaded.play();
    }

    getRandomElement(array) {
        return array[Math.random() * array.length >> 0];
    }

    beginTutorial() {
        setTimeout(() => {
            this.doStepOne();
        }, 2500);
    }

    sleep(time) {
        return new Promise((resolve) => setTimeout(resolve, time));
    }

    async doStepOne() {
        this.setState({ showStepOne: true, showStepOneTextOne: true, });
        await this.sleep(1000);
        let stepOnePlayers = [...this.state.stepOnePlayers];
        stepOnePlayers.forEach(async (x, i) => {
            await this.sleep(200);
            stepOnePlayers[i].show = true;
            this.setState({ stepOnePlayers, });
        });

        await this.sleep(2000);
        this.setState({ showStepOneTextTwo: true });

        await this.sleep(5000);
        this.setState({ showStepOne: false, });
        await this.sleep(1000);
        this.doStepTwo();
    }

    async doStepTwo() {
        this.setState({ showStepTwo: true, });
        await this.sleep(1000);
        this.setState({ showStepTwoText: true, });

        await this.sleep(1500);
        let showStepTwoLetters = [...this.state.showStepTwoLetters];
        for (let i = 0; i < this.state.stepTwoAnagram.length; i++) {
            await this.sleep(250)
            showStepTwoLetters[i] = true;
            this.setState({ showStepTwoLetters });
        }

        await this.sleep(5000);
        this.setState({ showStepTwo: false, });

        await this.sleep(1000);
        this.doStepThree();
    }

    async doStepThree() {
        this.setState({ showStepThree: true, });
        await this.sleep(1000);
        this.setState({ showStepThreeTextOne: true, });
        await this.sleep(1500);
        this.setState({ showStepThreeBubble: true, });
        await this.sleep(1500);
        this.setState({ showStepThreeTextTwo: true, });
        await this.sleep(5000);
        this.setState({ showStepThree: false, });
        await this.sleep(1000);
        this.doStepFour();
    }

    async doStepFour() {
        this.setState({ showStepFour: true, });
        await this.sleep(1000);
        this.setState({ showStepFourText: true, });
        await this.sleep(1500);
        this.setState({ showStepFourBubble: true, });
        await this.sleep(5000);
        this.setState({ showStepFour: false, });
        await this.sleep(1000);
        this.doStepFive();
    }

    async doStepFive() {
        this.setState({ showStepFive: true });
        await this.sleep(1000);
        this.setState({ showStepFiveText: true });
        await this.sleep(1500);
        this.setState({ showStepFiveTitle: true });
        await this.sleep(5000);
        this.setState({ showStepFive: false, });
        await this.sleep(1000);
        this.doStepSix();
    }

    async doStepSix() {
        this.setState({ showStepSix: true });
        await this.sleep(1000);
        this.setState({ showStepSixText: true });
        await this.sleep(1500);
        let showStepSixLetters = [...this.state.showStepSixLetters];
        for (let i = 0; i < this.state.stepTwoAnagram.length; i++) {
            await this.sleep(250)
            showStepSixLetters[i] = true;
            this.setState({ showStepSixLetters });
        }
        await this.sleep(2000);
        this.setState({ showStepSixAnswer: true });
        await this.sleep(5000);

        this.setState({ showStepSix: false, });
        await this.sleep(1000);
        this.doStepSeven();
    }

    async doStepSeven() {
        this.setState({ showStepSeven: true });
        await this.sleep(1000);
        this.setState({ showStepSevenText: true });
        await this.sleep(1500);
        this.setState({ showStepSevenPodium: true });
        await this.sleep(5000);

        this.setState({ showStepSeven: false, });
        await this.sleep(1000);
        this.doStepEight();
    }

    async doStepEight() {
        this.setState({ showStepEight: true, });
        await this.sleep(1000);
        this.setState({ showStepEightText: true, });
        await this.sleep(5000);
        this.setState({ showStepEight: false, });
        this.props.room.send("end_tutorial", {});
    }

    getVoteCount() {
        let count = 0;
        this.props.players.forEach((x) => {
            if (x.votedSkip) count++;
        });
        return count;
    }

    podiumPosition(player, pos, style) {
        return (
            <div className={`${styles.potato} ${style} ${styles.raise}`}>
                <div className={`${styles.nameBox}`}>
                    <div className={`${styles.name}`}>
                        {player.name}
                    </div>
                </div>
                <Lottie
                    options={getAvatarById(player.avatar).idleAnim}
                    width="100%"
                    height="100%"
                    isClickToPauseDisabled={true}
                />
            </div >
        )
    }


    render() {
        return (
            <div id="tutorialContainer" className={styles.tutorialContainer}>
                <div className={styles.voteCount}>Skip Votes: {this.getVoteCount()} / {this.props.players.length}</div>
                <div className={`${styles.stepOne} ${styles.step} ${this.state.showStepOne && styles.show}`}>
                    <div className={`${styles.text} ${this.state.showStepOneTextOne && styles.show}`}>Get ready...</div>
                    <div className={styles.playerRow}>
                        {
                            this.state.stepOnePlayers.map((x) => {
                                return <div className={`${styles.player} ${x.show && styles.show}`}>
                                    <Lottie
                                        options={getAvatarById(x.avatar).idleAnim}
                                        width="100%"
                                        height="100%"
                                        isClickToPauseDisabled={true}
                                    />
                                </div>
                            })
                        }
                    </div>
                    <div className={`${styles.text} ${this.state.showStepOneTextTwo && styles.show}`}>...It's time to play 'Anagram Magic'</div>
                </div>
                <div className={`${styles.stepTwo} ${styles.step} ${this.state.showStepTwo && styles.show}`} style={{ width: "100%" }}>
                    <div className={`${styles.text} ${this.state.showStepTwoText && styles.show}`}>Each round a player will select 9 letters at random...</div>
                    <div className={`${styles.anagramSection}`}>
                        <div className={styles.anagram}>
                            {
                                this.state.stepTwoAnagram.split('').map((x, index) => {
                                    return <div className={`${styles.anagramLetter} ${this.state.showStepTwoLetters[index] && styles.show}`}>
                                        <img src={WordTileImg} className={styles.wordTile} alt="word-tile" />
                                        <div className={styles.letter}>{x}</div>
                                    </div>
                                })
                            }
                        </div>
                    </div>
                </div>
                <div className={`${styles.stepThree} ${styles.step} ${this.state.showStepThree && styles.show}`}>
                    <div className={`${styles.text} ${this.state.showStepThreeTextOne && styles.show}`}>Submit the longest word you can each round</div>
                    <img src={StepThreeBubble} className={`${styles.stepThreeBubble} ${this.state.showStepThreeBubble && styles.show}`} alt="step-tree-bubble"/>
                    <div className={`${styles.text} ${this.state.showStepThreeTextTwo && styles.show}`}>Earn points based on word length and bonus points for the longest word!</div>
                </div>
                <div className={`${styles.stepFour} ${styles.step} ${this.state.showStepFour && styles.show}`}>
                    <div className={`${styles.text} ${this.state.showStepFourText && styles.show}`}>You can't use a single letter more than once</div>
                    <img src={StepFourBubble} className={`${styles.stepFourBubble} ${this.state.showStepFourBubble && styles.show}`} alt="step-four-bubble" />
                </div>

                <div className={`${styles.stepFive} ${styles.step} ${this.state.showStepFive && styles.show}`}>
                    <div className={`${styles.text} ${this.state.showStepFiveText && styles.show}`}>Lookout for the...</div>
                    <div className={`${styles.roundNumberSection}`}>
                        <div className={`${styles.roundTextContainer} ${this.state.showStepFiveTitle && styles.show}`}>
                            <div className={`${styles.roundText}`}>
                                <p className={`${styles.gradiant} ${styles.gold}`}>BONUS ROUND</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`${styles.stepSix} ${styles.step} ${this.state.showStepSix && styles.show}`}>
                    <div className={`${styles.text} ${this.state.showStepSixText && styles.show}`}>Find the 9 Letter anagram to score points!</div>
                    <div className={`${styles.anagramSection}`}>
                        <div className={styles.anagram}>
                            {
                                this.state.stepSixAnagram.split('').map((x, index) => {
                                    return <div className={`${styles.anagramLetter} ${this.state.showStepSixLetters[index] && styles.show}`}>
                                        <img src={WordTileImg} className={styles.wordTile} alt="word-tile" />
                                        <div className={styles.letter}>{x}</div>
                                    </div>
                                })
                            }
                        </div>
                    </div>
                    <div className={`${styles.answer} ${this.state.showStepSixAnswer && styles.show}`}>
                        <div className={`${styles.answerBox}`} >
                            <p className={styles.answerText} >{this.state.stepSixAnswer}</p>
                        </div>
                    </div>
                </div>
                <div className={`${styles.stepSeven} ${styles.step} ${this.state.showStepSeven && styles.show}`}>
                    <div className={`${styles.text} ${this.state.showStepSevenText && styles.show}`}>Only the first 3 players to get it, win points!</div>
                    <div className={`${styles.podium} ${this.state.showStepSevenPodium && styles.show}`}>
                        {this.podiumPosition(this.state.stepSevenPlayers[1], "2nd")}
                        {this.podiumPosition(this.state.stepSevenPlayers[0], "1st", styles.first)}
                        {this.podiumPosition(this.state.stepSevenPlayers[2], "3rd")}
                    </div>
                </div>
                <div className={`${styles.stepEight} ${styles.step} ${this.state.showStepEight && styles.show}`}>
                    <div className={`${styles.text} ${styles.larger} ${this.state.showStepEightText && styles.show}`}>GOOD LUCK!</div>
                </div>
            </div>
        );
    }
}