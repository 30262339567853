/* eslint-disable default-case */
import React, { Component } from 'react';
import { Route } from 'react-router';
import * as Sentry from "@sentry/react";

import * as Colyseus from "colyseus.js";
import Lottie from 'react-lottie';
import nosleep from 'nosleep.js';
import "animate.css";

import Loading from "components/Loading";
import DefaultView from "components/MathsMaster/DefaultView";

import styles from './MathsClientStyles.module.scss';

import TileImg from "images/MathsMaster/square-tile.png";
import ButtonImg1 from "images/MathsMaster/button-1.png";
import ButtonImg2 from "images/MathsMaster/button-2.png";
import GoalBg from "images/MathsMaster/big-number-banner.png";
import NumbersBg from "images/MathsMaster/number-tile-group.png";
import ClockImg from "images/MathsMaster/clock.png";
import ClockHandImg from "images/MathsMaster/clock-hand.png";
import ClockBg from "images/MathsMaster/clock-bg.png";


var noSleep = new nosleep();
var supportsVibrate = "vibrate" in navigator;

const GameStates = {
    Loading: "loading",
    Tutorial: "tutorial",
    Idle: "idle",
    Playing: "playing",
    GameOver: "game_over",
    EndGame: "end_game",
};

const gameId = "maths_master";

const operators = [
    {
        display: "×",
        type: "multiply"
    },
    {
        display: "+",
        type: "add",
    },
    {
        display: "−",
        type: "minus",
    },
    {
        display: "÷",
        type: "divide"
    }
];

export class MathsClient extends Component {
    static displayName = MathsClient.name;

    constructor(props) {
        super(props);

        this.client = new Colyseus.Client(process.env.REACT_APP_GAME_SERVER_URL);
        this.state = {
            roomId: 0,
            room: null,
            //room: {}, //debug
            myId: null,
            roomState: null,
            redirect: null,
            redirectURL: "",
            isPaused: false,
            hostConnected: false,
            players: [],

            player: {
                mathsData: {}
            },
            doingTutorial: false,

            showAnswerView: false,
            numberGoal: 0,

            showLastNumberView: false,

            selectedNumbers: [],
            selectedIndexes: [],
            selectedOperator: null,

            timerText: 0,
            showTimer: false,

            reconnectionToken: "",
        };
        this.locationCheckInterval = null;
    }

    componentDidMount() {
        this.setTags();
        setTimeout(() => {
            this.doReconnect();
        }, 1500);

        document.addEventListener('click', function enableNoSleep() {
            document.removeEventListener('click', enableNoSleep, false);
            noSleep.enable();
        }, false);
    }

    setTags() {
        const token = this.getQueryStringValue('token');
        Sentry.setTag('isPlayer', true);

        if (token) {
            const [roomId, reconnectToken] = token.split(':');
            Sentry.setTag('roomId', roomId);
            Sentry.setTag('reconnectToken', reconnectToken);
        }
    }

    getQueryStringValue(key) {
        return decodeURIComponent(window.location.search.replace(new RegExp("^(?:.*[&\\?]" + encodeURIComponent(key).replace(/[.+*]/g, "\\$&") + "(?:\\=([^&]*))?)?.*$", "i"), "$1"));
    }

    // update this with your gamestates, if player joins/reconnects mid-game
    doCatchup(state) {
        const player = state.players[this.state.myId];
        switch (state.mathsData.gameState) {
            case GameStates.Tutorial:
                if (player.votedSkip === false) {
                    this.setState({ doingTutorial: true });
                }
                break;
            case GameStates.Playing:
                //this.state.room.sed("request_question", {});
                if (!player.mathsData.ready) {
                    this.setState({ showAnswerView: true, currentAnagram: state.mathsData.currentAnagramShuffled, showTimer: true })
                } else {
                    this.setState({ showAnswerView: false, showLastNumberView: false});
                }
                break;
            case GameStates.Idle:
                this.setState({ showAnswerView: false, doingTutorial: false, showLastNumberView: false, });
                this.resetRoundVals();
                break;
            default:
                break;
        }
    }

    resetRoundVals() {
        this.setState({
            currentAnagram: "",
            currentAnswer: "",
            selectedIndexes: [],
            selectedNumbers: [],
            selectedOperator: null
        });
    }

    // checking to ensure player is in the right place e.g. in the correct game
    startLocationChecks() {
        this.state.room.send("location_check", { gameId, });
        this.locationCheckInterval = setInterval(() => {
            this.state.room.send("location_check", { gameId, });
        }, 10000);
    }

    skipTutorial() {
        this.state.room.send("vote_skip");
        this.setState({ doingTutorial: false });
    }

    goToLobby() {
        this.setState({ redirectURL: `${this.getRedirectURL()}/?token=${this.state.reconnectionToken}` });
        this.state.room.leave(false);
        if (this.locationCheckInterval) clearInterval(this.locationCheckInterval);
    }

    getRedirectURL(display = false) {
        let url = display ? process.env.REACT_APP_GAME_CITY_URL_DISPLAY : process.env.REACT_APP_GAME_CITY_URL;
        if (this.state.room) {
            if (this.state.room.name !== "game_city_room") {
                url = display ? process.env.REACT_APP_HOME_URL_DISPLAY : process.env.REACT_APP_HOME_URL;
            }
        }
        return url;
    }

    shuffle(string) {
        return string.split('')
            .sort(() => 0.5 - Math.random())
            .join('');
    }

    shouldShowDefaultView() {
        return !this.state.showAnswerView && !this.state.doingTutorial;
    }

    clickNumber(number, selectedIndex, isAdd) {
        if (number == null) {
            return;
        }

        let selectedIndexes = this.state.selectedIndexes;
        let selectedNumbers = this.state.selectedNumbers;

        console.log("Selected Index : ", selectedIndex);

        if (isAdd) {
            if (selectedIndexes.includes(selectedIndex)) {
                let index = selectedIndexes.indexOf(selectedIndex);
                selectedIndexes.splice(index, 1);
                selectedNumbers.splice(index, 1);
            } else {
                if (selectedNumbers.length < 2) {
                    selectedIndexes.push(selectedIndex);
                    selectedNumbers.push(number);
                }
            }
        } else {
            selectedIndexes.splice(selectedIndex, 1);
            selectedNumbers.splice(selectedIndex, 1);
        }

        console.log("Selected Indexes : ", selectedIndexes);
        console.log("Selected Numbers : ", selectedNumbers);
        this.setState({ selectedIndexes: selectedIndexes, selectedNumbers: selectedNumbers, });
    }

    clickOperator(operator) {
        if (operator == null) {
            return;
        }

        let selectedOperator = this.state.selectedOperator;
        if (selectedOperator == null) {
            console.log("operator : ", operator);
            selectedOperator = operator;
            this.setState({ selectedOperator: operator });
        } else {
            if (operator.type === selectedOperator.type) {
                this.setState({ selectedOperator: null });
            }
        }
    }

    submitEquation = () => {
        if (this.canSubmitEquation()) {
            let number1 = this.state.selectedNumbers[0];
            let number2 = this.state.selectedNumbers[1];
            let operator = this.state.selectedOperator;

            let answer;
            switch (operator.type) {
                case "multiply":
                    answer = number1 * number2;
                    break;
                case "add":
                    answer = number1 + number2;
                    break;
                case "minus":
                    answer = number1 - number2;
                    break;
                case "divide":
                    answer = number1 / number2;
                    break;
            }

            console.log("Number 1 : ", number1);
            console.log("Operator : ", operator);
            console.log("Number 2 : ", number2);
            console.log("Answer : ", answer);
            answer = Math.round(answer);

            this.state.room.send("submit_equation", { number1, number2, operator: operator.display, answer, });
            this.setState({ selectedNumbers: [], selectedIndexes: [], selectedOperator: null });
        }
    }

    resetNumbers = () => {
        this.state.room.send("reset_numbers");
        let statePlayer = { ...this.state.player };
        statePlayer.mathsData.playerNumbers = [...statePlayer.mathsData.initialPlayerNumbers];
        //statePlayer.mathsData.equationHistory.clear();
        this.setState({ player: statePlayer, selectedNumbers: [], selectedIndexes: [], selectedOperator: null });
    }

    undo = () => {
        let statePlayer = { ...this.state.player };
        //if (!statePlayer.mathsData.equationHistory || statePlayer.mathsData.equationHistory.length === 0) return;
        this.state.room.send("mm_undo");
        //console.log(statePlayer.mathsData.equationHistory);
        //const strings = statePlayer.mathsData.equationHistory.pop().split(" ").filter(n => !isNaN(n));
        //const lastNumbers = [];
        //for (let i = 0; i < strings.length; i++) lastNumbers.push(parseInt(strings[i]));
        //console.log(lastNumbers);
        ////statePlayer.mathsData.playerNumbers.splice(statePlayer.mathsData.playerNumbers.indexOf(lastNumbers[2]), 1);
        ////statePlayer.mathsData.playerNumbers.push(lastNumbers[0]);
        ////statePlayer.mathsData.playerNumbers.push(lastNumbers[1]);
        console.log("numbers", statePlayer.mathsData.playerNumbers)
        this.setState({ player: statePlayer, selectedNumbers: [], selectedIndexes: [], selectedOperator: null });
    }

    canSubmitEquation() {
        if (this.state.selectedOperator != null && this.state.selectedNumbers.length === 2) {
            return true;
        }

        return false;
    }

    //canReset = () => {
    //    return this.state.mathsData.playerNumbers.length !== this.state.mathsData.initialPlayerNumbers.length;
    //}

    clickDone = () => {
        this.setState({ showLastNumberView: true, });
    }

    getClosestNumber() {
        const target = this.state.numberGoal;
        const playerNumbers = this.state.player.mathsData.playerNumbers;
        let closest = null;

        for (let i = 1; i < playerNumbers.length; i++) {
            if (typeof playerNumbers[i] === 'number') {
                if (closest === null) {
                    closest = playerNumbers[i];
                }
                else if (Math.abs(playerNumbers[i] - target) < Math.abs(closest - target)) {
                    closest = playerNumbers[i];
                }
            }
        }

        return closest;
    }

    submitNumber = () => {
        this.setState({ showAnswerView: false, showLastNumberView: false, showTimer: false });
        this.state.room.send("submit_final_number", { number: this.getClosestNumber(), });
    }

    updateToken(token) {
        var url = new URL(window.location.href);

        try {
            window.history.replaceState(null, null, (url.pathname) + (`?token=${token}`));
        } catch (e) {
            console.warn(e)
        }
    }

    checkAndAddPlayer(player) {
        if (!this.state.players.find(elem => elem.id === player.id)) {
            console.log("Adding player to bonus actions : ", player.id);
            this.setState((prevState) => {
                return { players: [...prevState.players, player] }
            });
        }
    }

    doReconnect = () => {
        // fetch room and session id from query params
        //const roomId = this.getQueryStringValue("roomId");
        //const sessionId = this.getQueryStringValue("sessionId");
        const token = this.getQueryStringValue("token");

        // start reconnecting player to game
        this.client.reconnect(token).then(room => {
            console.log(room.sessionId, "joined", room.name);
            this.setState({ room: room, roomId: room.id, myId: room.sessionId, reconnectionToken: room.reconnectionToken });
            this.updateToken(room.reconnectionToken);
            room.send("update_player_token", { reconnectionToken: room.reconnectionToken });

            room.onStateChange.once((state) => {
                console.log("this is the first room state!", state);
                const player = state.players[room.sessionId];
                if (!player) window.location = this.getRedirectURL();
                Sentry.setUser({ id: player.uniqueId });
                this.setState({ isPaused: state.isPaused, });

                this.startLocationChecks();
                this.doCatchup(state);

                room.state.mathsData.listen("numberGoal", (value) => {
                    console.log("new number goal : ", value);
                    this.setState({ numberGoal: value });
                });
            });

            //room.onStateChange((state) => {
            //    console.log("room has new state:", state);
            //    this.setState({ roomState: state });
            //    this.doCatchup(state);
            //});

            room.state.host.listen("connected", (value) => {
                this.setState({ hostConnected: value });
            });

            room.state.players.onAdd((player, key) => {
                this.checkAndAddPlayer(player);
                player.listen("connected", (currentValue, previousValue) => {
                    let statePlayers = [...this.state.players];
                    let index = statePlayers.findIndex(elem => elem.id === player.id);
                    statePlayers[index].connected = currentValue;
                    this.setState({ players: statePlayers });
                });

                if (key === room.sessionId) {
                    const changes = ["connected", "votedSkip", "avatar", "name", "id", "primaryPlayer"];
                    changes.forEach(change => {
                        player.listen(change, (value) => {
                            let statePlayer = { ...this.state.player };
                            statePlayer[change] = value;
                            this.setState({ player: statePlayer });
                        });
                    });

                    const mathsPlayerChanges = ["score", "answer", "ready", "submittedNumber", "hasEarnedPoints"];

                    mathsPlayerChanges.forEach(change => {
                        player.mathsData.listen(change, (value) => {
                            let statePlayer = { ...this.state.player };
                            statePlayer.mathsData[change] = value;
                            this.setState({ player: statePlayer });
                        });
                    });

                    const mathsPlayerArrayChanges = ["equationHistory", "playerNumbers", "initialPlayerNumbers"];

                    mathsPlayerArrayChanges.forEach(arrayName => {
                        let statePlayer = { ...this.state.player };
                        statePlayer.mathsData[arrayName] = [];
                        this.setState({ player: statePlayer });
                        player.mathsData[arrayName].onChange((change, index) => {
                            console.log("!!! Begin Array change for: ", arrayName);
                            //console.log("Before Change", [...this.state.player.mathsData[arrayName]]);
                            let statePlayer = { ...this.state.player };
                            console.log("curValue ", statePlayer.mathsData[arrayName][index], "newValue ", change, "index", index);

                            //if (!change) statePlayer.mathsData[arrayName].splice(index, 1);
                            //else
                            statePlayer.mathsData[arrayName][index] = change;

                            //console.log("After Change", [...statePlayer.mathsData[arrayName]]);
                            this.setState({ player: statePlayer });
                        });
                    });
                }
            });

            room.onMessage("show_tutorial", (message) => {
                console.log("show_tutorial", "received on", room.name, message);
                this.setState({ doingTutorial: true });
            });
            room.onMessage("end_tutorial", (message) => {
                console.log("end_tutorial", "received on", room.name, message);
                this.setState({ doingTutorial: false });
            });
            room.onMessage("toggle_pause", (message) => {
                console.log("toggle_pause", "received on", room.name, message);
                this.setState({ isPaused: message.pause });
            });

            // your game message handlers here
            room.onMessage("round_started", (message) => {
                console.log("round_started", "received on", room.name, message);
                this.setState({ showAnswerView: true, showTimer: true })
                this.resetNumbers();
            });

            //room.onMessage("end_round", (message) => {
            //    console.log("end_round", "received on", room.name, message);
            //    this.setState({ showAnswerView: false, })
            //});

            room.onMessage("force_finish", (message) => {
                console.log("force_finish", "received on", room.name, message);
                this.setState({ showAnswerView: false, showTimer: false, showLastNumberView: false})
            });

            room.onMessage("game_starting", (message) => {
                console.log("game_starting", "received on", room.name, message);
                if (message.gameId !== gameId) {
                    this.goToLobby();
                }
            });
            room.onMessage("host_joined_lobby", (message) => {
                console.log("host_joined_lobby", "received on", room.name, message);
                this.goToLobby();
            });
            room.onMessage("change_game", (message) => {
                console.log("change_game", "received on", room.name, message);
                this.goToLobby();
            });

            room.onMessage("update_timer", (message) => {
                console.log("update_timer", "received on", room.name, message);
                this.setState({ timerText: message.count});
            });

            room.onError((code, message) => {
                Sentry.captureMessage(`WS Received: onError; message: ${message}`);
                console.log(this.client.id, "couldn't join", room.name);
            });

            room.onLeave((code) => {
                console.log(this.client.id, "left", room.name);

                if (!this.state.redirectURL) {
                    if (code === 4050) {
                        this.setState({ redirect: true, redirectURL: `${this.getRedirectURL()}/` });
                        if (this.locationCheckInterval) clearInterval(this.locationCheckInterval);
                    } else {
                        this.doReconnect();
                    }
                } else {
                    setTimeout(() => {
                        this.setState({ redirect: true, });
                    }, 1500);
                }
            });
        }).catch(e => {
            console.log("JOIN ERROR", e);
            this.setState({ redirect: true, redirectURL: `${this.getRedirectURL()}/` });
            const message = e.message ? e.message : "An error occured joining Maths Master.";
            if (!message.includes("has been disposed")) Sentry.captureMessage(`doReconnect Error: ${message}`);
            if (this.locationCheckInterval) clearInterval(this.locationCheckInterval);
        });
    }

    render() {
        if (this.state.redirectURL) {
            return (
                <React.Fragment>
                    <div id="clientContainer" className={styles.clientContainer}>
                        <Loading loadingText={"Sending you to the lobby!"} />
                    </div>

                    <div style={{ opacity: 0 }}>
                        {
                            this.state.redirect ?
                                <Route path="/" render={() => (window.location = this.state.redirectURL)} />
                                :
                                null
                        }
                    </div>
                </React.Fragment>
            )
        }
        return (
            <div>
                {
                    this.state.room ?
                        <div id="clientContainer" className={styles.clientContainer}>
                            {
                                this.shouldShowDefaultView() &&
                                <DefaultView room={this.state.room} player={this.state.player} hostConnected={this.state.hostConnected} players={this.state.players} />
                            }
                            {
                                this.state.isPaused &&
                                <div className={styles.pauseContainer}>
                                    <div className={styles.pauseText}>Paused</div>
                                </div>
                            }
                            {
                                this.state.showAnswerView &&
                                <div className={styles.answerSection}>
                                    <div className={`${styles.goalSection}`}>
                                        <img className={styles.goalBg} src={GoalBg} alt="goal-bg"/>
                                        <div className={styles.goalText}>{this.state.numberGoal}</div>
                                    </div>
                                    <div className={`${styles.infoBox}`}>
                                        <div className={styles.background} />
                                        <div className={styles.innerText}>Solve the equation!</div>
                                    </div>
                                    <div className={`${styles.numbersGroup}`}>
                                        <img className={styles.numbersBg} src={NumbersBg} alt="numbers-bg"/>
                                        <div className={styles.numbersRow}>
                                            {
                                                this.state.player.mathsData.playerNumbers.filter(x=>x).map((x, index) => {
                                                    const isSelected = this.state.selectedIndexes.filter((x) => x === index).length === 0;
                                                    return <div onClick={() => this.clickNumber(x, index, true)} className={`${styles.number} ${x.toString().length === 4 && styles.small} ${x.toString().length === 5 && styles.xSmall} ${x.toString().length === 6 && styles.tiny} ${((this.state.selectedIndexes.length === 2 && isSelected) || !isSelected) && styles.fade}`}>{x}</div>
                                                })
                                            }
                                        </div>
                                    </div>
                                    <div className={`${styles.operatorsGroup}`}>
                                        {
                                            operators.map((x) => {
                                                return <div onClick={() => this.clickOperator(x)} className={`${styles.operator} ${this.state.selectedOperator != null && this.state.selectedOperator.type !== x.type && styles.fade}`}>
                                                    <img className={styles.operatorBg} src={TileImg} alt="operator-bg"/>
                                                    <div className={styles.operatorText}>{x.display}</div>
                                                </div>
                                            })
                                        }
                                    </div>
                                    <div className={styles.answerBox}>
                                        <div className={styles.background} />
                                        <div className={`${styles.answerSegment} ${this.state.selectedNumbers[0]?.toString().length === 4 && styles.small} ${this.state.selectedNumbers[0]?.toString().length === 5 && styles.xSmall} ${this.state.selectedNumbers[0]?.toString().length === 6 && styles.tiny}`} onClick={() => this.clickNumber(this.state.selectedNumbers[0], 0, false)}>{this.state.selectedNumbers[0]}</div>
                                        <div className={styles.answerSegment} onClick={() => this.clickOperator(this.state.selectedOperator)}>{this.state.selectedOperator?.display}</div>
                                        <div className={`${styles.answerSegment} ${this.state.selectedNumbers[1]?.toString().length === 4 && styles.small} ${this.state.selectedNumbers[1]?.toString().length === 5 && styles.xSmall} ${this.state.selectedNumbers[1]?.toString().length === 6 && styles.tiny}`} onClick={() => this.clickNumber(this.state.selectedNumbers[1], 1, false)}>{this.state.selectedNumbers[1]}</div>
                                    </div>
                                    <button className={`${styles.button} ${styles.smaller}`} onClick={this.submitEquation}>
                                        <img className={styles.buttonImg} src={ButtonImg1} alt="submit button" />
                                        Combine
                                    </button>
                                    <button className={`${styles.button} ${styles.smaller}`} onClick={this.undo}>
                                        <img className={styles.buttonImg} src={ButtonImg1} alt="reset button" />
                                        Undo
                                    </button>
                                    <button className={`${styles.button}`} onClick={this.clickDone} style={{ marginTop: "2vh" }}>
                                        <img className={styles.buttonImg} src={ButtonImg2} alt="done button" />
                                        Submit Answer
                                    </button>
                                    <div>
                                    </div>
                                </div>
                            }
                            {
                                this.state.showLastNumberView &&
                                <div className={styles.lastNumberSection}>
                                    <div className={styles.opacityBackground}></div>
                                    <div className={styles.innerBox}>
                                        <div className={styles.bestNumber}>
                                            <img className={styles.bestNumberBg} src={GoalBg} alt="best-number-bg"/>
                                            <div className={styles.bestNumberText}>{this.getClosestNumber()}</div>
                                        </div>
                                        <p className={styles.innerText}>Do you want to submit this as your closest number?</p>
                                        <div className={styles.buttonGroup}>
                                            <button className={`${styles.button} ${styles.smaller}`} onClick={() => this.setState({ showLastNumberView: false, })}>
                                                <img className={`${styles.buttonImg}`} src={ButtonImg1} alt="no button" />
                                                No
                                            </button>
                                            <button className={`${styles.button} ${styles.smaller}`} onClick={this.submitNumber}>
                                                <img className={`${styles.buttonImg}`} src={ButtonImg2} alt="done button" />
                                                Submit
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            }
                            {
                                <div className={`${styles.timerSection} ${this.state.showTimer && styles.show}`}>
                                    <img src={ClockBg} className={styles.clockBg} alt="clock-bg" />
                                    <div className={styles.clock}>
                                        <img src={ClockImg} className={styles.clockImg} alt="clock" />
                                        <img src={ClockHandImg} className={styles.clockHand} alt="clock-hand" />
                                    </div>
                                    <div className={styles.timerText}>{this.state.timerText}</div>
                                </div>
                            }
                            {
                                this.state.doingTutorial &&
                                <div className={styles.skipContainer} onClick={() => this.skipTutorial()}>
                                    <div className={styles.skipButton}>Skip Tutorial</div>
                                </div>
                            }
                        </div>
                        :
                        <Loading loadingText={"Connecting you to the game..."} noBg={true} hideLoader={false} />
                }
            </div>
        );
    }
}
