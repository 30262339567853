import React, { Component } from 'react';
import { Route } from 'react-router';
import * as Sentry from "@sentry/react";

import * as Colyseus from "colyseus.js";
import Lottie from 'react-lottie';
import nosleep from 'nosleep.js';
import "animate.css";

import Loading from "components/Loading";
import DefaultView from "components/WordWheel/DefaultView";

import styles from './WordClientStyles.module.scss';

import ButtonImg2 from "images/WordWheel/button-2.png";
import WheelBg from "images/WordWheel/wheel.png";
import CrossImg from "images/WordWheel/cross.png";
import StarImg from "images/WordWheel/star.png";
import { v4 as uuid } from 'uuid';


var noSleep = new nosleep();
var supportsVibrate = "vibrate" in navigator;

const GameStates = {
    Loading: "loading",
    Tutorial: "tutorial",
    Idle: "idle",
    Playing: "playing",
    GameOver: "game_over",
    EndGame: "end_game",
};

const gameId = "word_wheel";

export class WordClient extends Component {
    static displayName = WordClient.name;

    constructor(props) {
        super(props);

        this.client = new Colyseus.Client(process.env.REACT_APP_GAME_SERVER_URL);
        this.state = {
            roomId: 0,
            room: null,
            myId: null,
            roomState: null,
            redirect: null,
            redirectURL: "",
            isPaused: false,
            gameState: GameStates.Loading,
            hostConnected: false,
            players: [],

            player: {
                wordData: {}
            },

            currentWordShuffled: "",
            currentAnswer: "",
            selectedIndexes: [],
            reconnectionToken: "",

            wordValid: 0,
            showWordValid: false,
            wordValidationText: "",
            wordValidationKey: 0,
        };
        this.locationCheckInterval = null;
        this.validationTimeout = undefined;
    }

    componentDidMount() {
        this.setTags();
        setTimeout(() => {
            this.doReconnect();
        }, 1500);

        document.addEventListener('click', function enableNoSleep() {
            document.removeEventListener('click', enableNoSleep, false);
            noSleep.enable();
        }, false);
    }

    setTags() {
        const token = this.getQueryStringValue('token');
        Sentry.setTag('isPlayer', true);

        if (token) {
            const [roomId, reconnectToken] = token.split(':');
            Sentry.setTag('roomId', roomId);
            Sentry.setTag('reconnectToken', reconnectToken);
        }
    }

    getQueryStringValue(key) {
        return decodeURIComponent(window.location.search.replace(new RegExp("^(?:.*[&\\?]" + encodeURIComponent(key).replace(/[\.\+\*]/g, "\\$&") + "(?:\\=([^&]*))?)?.*$", "i"), "$1"));
    }

    // update this with your gamestates, if player joins/reconnects mid-game
 

    resetRoundVals() {
        this.setState({
            // currentWordShuffled: "",
            currentAnswer: "",
            selectedIndexes: [],
        });
    }

    // checking to ensure player is in the right place e.g. in the correct game
    startLocationChecks() {
        this.state.room.send("location_check", { gameId, });
        this.locationCheckInterval = setInterval(() => {
            this.state.room.send("location_check", { gameId, });
        }, 10000);
    }

    skipTutorial() {
        this.state.room.send("vote_skip");
    }

    goToLobby() {
        this.setState({ redirectURL: `${this.getRedirectURL()}/?token=${this.state.reconnectionToken}` });
        this.state.room.leave(false);
        if (this.locationCheckInterval) clearInterval(this.locationCheckInterval);
    }

    getRedirectURL(display = false) {
        let url = display ? process.env.REACT_APP_GAME_CITY_URL_DISPLAY : process.env.REACT_APP_GAME_CITY_URL;
        if (this.state.room) {
            if (this.state.room.name != "game_city_room") {
                url = display ? process.env.REACT_APP_HOME_URL_DISPLAY : process.env.REACT_APP_HOME_URL;
            }
        }
        return url;
    }

    shuffle(string) {
        return string.split('')
            .sort(() => 0.5 - Math.random())
            .join('');
    }


    clickLetter(letter, selectedIndex, isAdd) {
        let selectedIndexes = this.state.selectedIndexes;
        let currentAnswer = this.state.currentAnswer;
        if (this.validationTimeout) {
            clearTimeout(this.validationTimeout);
            this.validationTimeout = null;
            currentAnswer = "";
            selectedIndexes = []
        }

        console.log("Selected Index : ", selectedIndex);

        if (isAdd) {
            if (selectedIndexes.includes(selectedIndex)) {
                let index = selectedIndexes.indexOf(selectedIndex);
                selectedIndexes.splice(index, 1);
                currentAnswer = currentAnswer.slice(0, index) + currentAnswer.slice(index + 1);
            } else {
                selectedIndexes.push(selectedIndex);
                currentAnswer += letter;
            }
        } else {
            selectedIndexes.splice(selectedIndex, 1);
            currentAnswer = currentAnswer.slice(0, selectedIndex) + currentAnswer.slice(selectedIndex + 1);
        }

        console.log("Selected Indexes : ", selectedIndexes);
        console.log("Current Answer : ", currentAnswer);
        this.setState({ selectedIndexes: selectedIndexes, currentAnswer: currentAnswer, wordValid: 0 });
    }

    submitAnswer = () => {
        const currentAnswer = this.state.currentAnswer;
        const selectedIndexes = this.state.selectedIndexes;

        if (currentAnswer.length >= 3 && selectedIndexes.includes(this.state.currentWordShuffled.length - 1)) {
            this.state.room.send("submit_word", { word: currentAnswer });
            //this.setState({ selectedIndexes: [], });
        }
    }

    updateToken(token) {
        var url = new URL(window.location.href);

        try {
            window.history.replaceState(null, null, (url.pathname) + (`?token=${token}`));
        } catch (e) {
            console.warn(e)
        }
    }

    getRenderView() {
        switch (this.state.gameState) {
            case GameStates.Tutorial:
                if (!this.state.player.votedSkip) {
                    return this.getTutorialView();
                } else {
                    return this.getDefaultView();
                }
            case GameStates.Playing:
                return this.getPlayingView();
            default:
                return this.getDefaultView();
        }
    }

    getDefaultView() {
        return <DefaultView room={this.state.room} player={this.state.player} hostConnected={this.state.hostConnected} players={this.state.players}  />
    }

    getTutorialView() {
        return <div className={styles.skipContainer} onClick={() => this.skipTutorial()}>
            <div className={styles.skipButton}>Skip Tutorial</div>
        </div>
    }

    getPlayingView() {
        return <div className={`${styles.answerSection}`}>
            <div className={`${styles.infoBox}`}>
                <div className={styles.background} />
                <div className={styles.innerText}>Find as many words as you can<br/> using the middle letter!</div>
            </div>
            <div className={styles.wheel} >
                <img className={styles.wheelBg} src={WheelBg} alt={"word-wheel-bg"} />
                <div className={styles.wheelContainer}>
                    {
                        this.state.currentWordShuffled.split("", this.state.currentWordShuffled.length).map((x, index) => {
                            if (index === this.state.currentWordShuffled.length - 1) {
                                return <div className={`${styles.centerCircle} ${this.state.selectedIndexes.includes(index) && (this.state.wordValid === 1 ? styles.valid : this.state.wordValid === 2 ? styles.invalid : styles.selected) }`} onClick={() => this.clickLetter(x, index, true)}>{x}</div>
                            } else {
                                return <div className={styles.segment} onClick={() => this.clickLetter(x, index, true)}>
                                    <div className={`${styles.segmentText} ${this.state.selectedIndexes.includes(index) && (this.state.wordValid === 1 ? styles.valid : this.state.wordValid === 2 ? styles.invalid : styles.selected) }`}>{x}</div>
                                </div>
                            }
                        })
                    }
                </div>
            </div>
            <div className={`${styles.answerLetters} ${this.state.wordValid === 1 && styles.valid} ${this.state.wordValid === 2 && styles.invalid}`}>
                <div className={styles.background} />
                <img key={this.state.wordValidationKey} className={`${styles.wordValidationImg} ${this.state.showWordValid && this.state.wordValid === 1 && styles.show}`} src={StarImg} alt="word validation correct" />
                <img key={this.state.wordValidationKey} className={`${styles.wordValidationImg} ${this.state.showWordValid && this.state.wordValid === 2 && styles.show}`} src={CrossImg} alt="word validation wrong" />
                {
                    this.state.currentAnswer.split('').map((x, index) => {
                        return <div className={`${styles.answerLetter}`} onClick={() => this.clickLetter(x, index, false)}>
                            <div className={styles.letter}>{x}</div>
                        </div>
                    })
                }
            </div>
            <button disabled={this.state.currentAnswer.length < 3 || !this.state.selectedIndexes.includes(this.state.currentWordShuffled.length - 1)} className={`${styles.button}`} onClick={this.submitAnswer}>
                <img className={styles.buttonImg} src={ButtonImg2} alt="submit button" />
                Submit
            </button>
            <div className={`${styles.infoBox} ${this.state.wordValidationText.length === 0 && styles.hide}`}>
                <div className={styles.background} />
                <div className={styles.innerText}>{this.state.wordValidationText}</div>
            </div>
        </div>
    }

    setWordValidation(valid, text = "") {
        clearTimeout(this.validationTimeout);
        this.setState({ wordValid: valid, showWordValid: true, wordValidationText: text, wordValidationKey: uuid() });
        this.validationTimeout = setTimeout(() => {
            this.setState({ wordValid: 0,  currentAnswer: "", selectedIndexes: [] });
        }, 500)
    }

    checkAndAddPlayer(player) {
        if (!this.state.players.find(elem => elem.id === player.id)) {
            console.log("Adding player to bonus actions : ", player.id);
            this.setState((prevState) => {
                return { players: [...prevState.players, player] }
            });
        }
    }

    doReconnect = () => {
        // fetch room and session id from query params
        const token = this.getQueryStringValue("token");

        // start reconnecting player to game
        this.client.reconnect(token).then(room => {
            console.log(room.sessionId, "joined", room.name);
            this.setState({ room: room, roomId: room.id, myId: room.sessionId, reconnectionToken: room.reconnectionToken });
            this.updateToken(room.reconnectionToken);
            room.send("update_player_token", { reconnectionToken: room.reconnectionToken });

            room.onStateChange.once((state) => {
                console.log("this is the first room state!", state);
                const player = state.players[room.sessionId];
                if (!player) window.location = this.getRedirectURL();
                Sentry.setUser({ id: player.uniqueId });
                this.setState({ isPaused: state.isPaused, });

                this.startLocationChecks();
            });

            room.state.host.listen("connected", (value) => {
                this.setState({ hostConnected: value });
            });

            room.state.players.onAdd((player) => {
                this.checkAndAddPlayer(player);
                player.listen("connected", (currentValue, previousValue) => {
                    let statePlayers = [...this.state.players];
                    let index = statePlayers.findIndex(elem => elem.id === player.id);
                    statePlayers[index].connected = currentValue;
                    this.setState({ players: statePlayers });
                });

                if (player.id === room.sessionId) {

                    const changes = ["connected", "votedSkip", "avatar", "name", "id", "primaryPlayer"];
                    changes.forEach(change => {
                        player.listen(change, (value) => {
                            let statePlayer = {...this.state.player};
                            statePlayer[change] = value;

                            this.setState({ player: statePlayer });
                        });
                    });

                    const wordPlayerChanges = ["score",];
                    wordPlayerChanges.forEach(change => {
                        player.wordData.listen(change, (value) => {
                            let statePlayer = { ...this.state.player };
                            statePlayer.wordData[change] = value;

                            this.setState({ player: statePlayer });
                        });
                    });

                    const wordPlayerArrayChanges = ["wordHistory",];
                    wordPlayerArrayChanges.forEach(arrayChange => {
                        let statePlayer = { ...this.state.player };
                        statePlayer.wordData[arrayChange] = [];
                        this.setState({ player: statePlayer });

                        player.wordData[arrayChange].onChange((change, index) => {
                            console.log(`${arrayChange} `, " change ", change, " index", index);
                            let statePlayer = { ...this.state.player };
                            statePlayer.wordData[arrayChange][index] = change;

                            this.setState({ player: statePlayer });
                        });
                    });
                }
            });

            const wordWheelStateVars = ["gameState", "roundNumber", "currentWordId", "currentWordShuffled"];
            wordWheelStateVars.forEach((stateVar) => {
                room.state.wordData.listen(stateVar, (change) => {
                    let update = {};
                    update[stateVar] = change;
                    this.setState(update);
                });
            });

            room.onMessage("show_tutorial", (message) => {
                console.log("show_tutorial", "received on", room.name, message);
            });
            room.onMessage("end_tutorial", (message) => {
                console.log("end_tutorial", "received on", room.name, message);
            });
            room.onMessage("toggle_pause", (message) => {
                console.log("toggle_pause", "received on", room.name, message);
                this.setState({ isPaused: message.pause });
            });

            // your game message handlers here
            room.onMessage("round_started", (message) => {
                console.log("round_started", "received on", room.name, message);
                this.resetRoundVals();
            });

            room.onMessage("game_starting", (message) => {
                console.log("game_starting", "received on", room.name, message);
                if (message.gameId != gameId) {
                    this.goToLobby();
                }
            });
            room.onMessage("host_joined_lobby", (message) => {
                console.log("host_joined_lobby", "received on", room.name, message);
                this.goToLobby();
            });
            room.onMessage("change_game", (message) => {
                console.log("change_game", "received on", room.name, message);
                this.goToLobby();
            });

            room.onMessage("word_used", (message) => {
                console.log("word_used", "received on", room.name, message);
                this.setWordValidation(2, "Word already used!");
            });
            room.onMessage("word_accepted", (message) => {  
                console.log("word_accepted", "received on", room.name, message);
                this.setWordValidation(1, "");
            });
            room.onMessage("word_not_using_center", (message) => {
                console.log("word_not_using_center", "received on", room.name, message);
                this.setWordValidation(2, "Word must use center letter!");
            });
            room.onMessage("word_not_valid", (message) => {
                console.log("word_not_valid", "received on", room.name, message);
                this.setWordValidation(2, "Word not valid!");
            });

            room.onError((code, message) => {
                Sentry.captureMessage(`WS Received: onError; message: ${message}`);
                console.log(this.client.id, "couldn't join", room.name);
            });
            room.onLeave((code) => {
                console.log(this.client.id, "left", room.name);

                if (!this.state.redirectURL) {
                    if (code == 4050) {
                        this.setState({ redirect: true, redirectURL: `${this.getRedirectURL()}/` });
                        if (this.locationCheckInterval) clearInterval(this.locationCheckInterval);
                    } else {
                        this.doReconnect();
                    }
                } else {
                    setTimeout(() => {
                        this.setState({ redirect: true, });
                    }, 1500);
                }
            });
        }).catch(e => {
            console.log("JOIN ERROR", e);
            this.setState({ redirect: true, redirectURL: `${this.getRedirectURL()}/` });
            const message = e.message ? e.message : "An error occured joining Word Wheel.";
            if (!message.includes("has been disposed")) Sentry.captureMessage(`doReconnect Error: ${message}`);
            if (this.locationCheckInterval) clearInterval(this.locationCheckInterval);
        });

    }

    render() {
        if (this.state.redirectURL) {
            return (
                <React.Fragment>
                    <div id="clientContainer" className={styles.clientContainer}>
                        <Loading loadingText={"Sending you to the lobby!"} />
                    </div>

                    <div style={{ opacity: 0 }}>
                        {
                            this.state.redirect ?
                                <Route path="/" render={() => (window.location = this.state.redirectURL)} />
                                :
                                null
                        }
                    </div>
                </React.Fragment>
            )
        }
        return (
            <div>
                {
                    this.state.room ?
                        <div id="clientContainer" className={styles.clientContainer}>
                            {
                                this.state.isPaused &&
                                <div className={styles.pauseContainer}>
                                    <div className={styles.pauseText}>Paused</div>
                                </div>
                            }
                            {
                                this.getRenderView()
                            }
                        </div>
                        :
                        <Loading loadingText={"Connecting you to the game..."} noBg={true} hideLoader={false} />
                }
            </div>
        );
    }
}
