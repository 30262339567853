import React, { Component } from 'react';
import * as Colyseus from "colyseus.js";
import { Route } from 'react-router';
import QRCode from 'qrcode.react';
import Lottie from 'react-lottie';
import { Howl, Howler } from "howler";
//import Confetti from 'react-confetti';
import LoggingService from "services/logging";
import * as Sentry from "@sentry/react";

import Loading from "components/Loading";
import Menu from "components/Menu";
import Player from "components/AnagramMagic/Player";
import Tutorial from "components/AnagramMagic/AnagramTutorial";

import getAvatarById from "constants/avatars";

import "animate.css";
import styles from 'components/AnagramMagic/AnagramHostStyles.module.scss';

import logo from "images/Host/anagram-magic-shop-logo.png";
import mutedIcon from "images/Host/lobby_muted.png";
import unmutedIcon from "images/Host/lobby_unmuted.png";
import fullscreenIcon from "images/Host/lobby_fullscreen.png";
import helpIcon from "images/Host/lobby_help.png";
import ButtonImg1 from "images/AnagramMagic/button-1.png";
import ButtonImg2 from "images/AnagramMagic/button-2.png";
import RaysImg from "images/AnagramMagic/rays.png";
import Round1Img from "images/AnagramMagic/round-1-text.png";
import Round2Img from "images/AnagramMagic/round-2-text.png";
import Round3Img from "images/AnagramMagic/round-3-text.png";
import WordTileImg from "images/AnagramMagic/word-tile.png";
import GoldWordTileImg from "images/AnagramMagic/word-tile-gold.png";
import WinnerBanner from "images/AnagramMagic/winner-banner.png";
import GameOverBanner from "images/AnagramMagic/game-over-banner.png";
import WinnersTitle from "images/AnagramMagic/winners-banner.png";
import LongestWordsTitle from "images/AnagramMagic/longest-words-banner.png";
import WordBanner from "images/AnagramMagic/word-banner.png";
import EndGameBox from "images/AnagramMagic/end-game-box.png";
import ClockImg from "images/AnagramMagic/clock.png";
import ClockHandImg from "images/AnagramMagic/clock-hand.png";
import ClockBg from "images/AnagramMagic/clock-bg.png";

import ClickSFX from "audio/click.wav";
import ClockTickSFX from "audio/clock-ticking.mp3";
import CorrectSFX from "audio/correct.wav";
import IncorrectSFX from "audio/correct.wav";
import ItemPopupSFX from "audio/item-popup.wav";
import RoundOverSFX from "audio/round-over.wav";
import SkipSFX from "audio/skip.wav";
import BGMusic from "audio/temp-bg-music.wav";
import WooshSFX from "audio/woosh.mp3";
import PopSFX from "audio/pop.wav";
import ReadySFX from "audio/ready.wav";
import FanfareSFX from "audio/fanfare.wav";
//import RevealSFX from "audio/reveal.wav";
import DrumRollSFX from "audio/drum-roll.wav";

const fullscreenAvailable = document.fullscreenEnabled || document.mozFullscreenEnabled || document.webkitFullscreenEnabled ? true : false;

var audio = {
    BgMusic: {
        import: BGMusic,
        loaded: null,
        volume: 0.15,
        loop: true,
    },
    Click: {
        import: ClickSFX,
        loaded: null,
        volume: 1,
        loop: false,
    },
    ClockTick: {
        import: ClockTickSFX,
        loaded: null,
        volume: 0.8,
        loop: false,
    },
    Correct: {
        import: CorrectSFX,
        loaded: null,
        volume: 1,
        loop: false,
    },
    Incorrect: {
        import: IncorrectSFX,
        loaded: null,
        volume: 1,
        loop: false,
    },
    ItemPopup: {
        import: ItemPopupSFX,
        loaded: null,
        volume: 1,
        loop: false,
    },
    RoundOver: {
        import: RoundOverSFX,
        loaded: null,
        volume: 1,
        loop: false,
    },
    Skip: {
        import: SkipSFX,
        loaded: null,
        volume: 1,
        loop: false,
    },
    Woosh: {
        import: WooshSFX,
        loaded: null,
        volume: 1,
        loop: false,
    },
    Pop: {
        import: PopSFX,
        loaded: null,
        volume: 1,
        loop: false,
    },
    Ready: {
        import: ReadySFX,
        loaded: null,
        volume: 1,
        loop: false,
    },
    Fanfare: {
        import: FanfareSFX,
        loaded: null,
        volume: 0.5,
        loop: false,
    },
    //Reveal: {
    //    import: RevealSFX,
    //    loaded: null,
    //    volume: 0.8,
    //    loop: false,
    //},
    DrumRoll: {
        import: DrumRollSFX,
        loaded: null,
        volume: 0.6,
        loop: false,
    },
};

const GameStates = {
    Loading: "loading",
    Tutorial: "tutorial",
    Idle: "idle",
    SelectingLetters: "selecting_letters",
    Playing: "playing",
    GameOver: "game_over",
    EndGame: "end_game",
};

const gameId = "anagram_magic";

const bonusLetters = /[QVWXYZ]/g

export class AnagramHost extends Component {
    static displayName = AnagramHost.name;

    constructor(props) {
        super(props);

        this.client = new Colyseus.Client(process.env.REACT_APP_GAME_SERVER_URL);
        this.state = {
            roomId: 0,
            room: null,
            myId: null,
            roomState: null,
            redirect: null,
            reconnectTries: 0,
            connected: false,
            //connected: true, //debug
            muted: false,
            menuOpen: false,
            tickedSkipTutorial: false,
            gameBegun: false,

            players: [],
            //players: [ //debug
            //    {
            //        name: "WWWWWWWWWW",
            //        id: "1",
            //        avatar: 1,
            //        anagramData: {
            //            answer: "answer",
            //            score: 1,
            //            ready: true,
            //            showAnswer: true,
            //            showValidation: false,
            //            isCorrect: false,
            //            answerHistory: []
            //        },
            //        showPlayer: true,
            //    },
            //    {
            //        name: "WWWWWWWWWW",
            //        id: "2",
            //        avatar: 2,
            //        anagramData: {
            //            answer: "answer",
            //            score: 1,
            //            ready: true,
            //            showAnswer: true,
            //            showValidation: false,
            //            isCorrect: false,
            //            answerHistory: []
            //        },
            //        showPlayer: true,
            //    },
            //    {
            //        name: "Player3",
            //        id: "3",
            //        avatar: 3,
            //        anagramData: {
            //            answer: "answer",
            //            score: 2,
            //            ready: true,
            //            showAnswer: true,
            //            showValidation: false,
            //            isCorrect: false,
            //            answerHistory: []
            //        },
            //        showPlayer: true,
            //    },
            //    {
            //        name: "Player4",
            //        id: "4",
            //        avatar: 4,
            //        anagramData: {
            //            answer: "answer",
            //            score: 2,
            //            ready: true,
            //            showAnswer: true,
            //            showValidation: false,
            //            isCorrect: false,
            //            answerHistory: [
            //                "Interest"]
            //        },
            //        showPlayer: true,
            //    },
            //    {
            //        "name": "Player5",
            //        "id": "5",
            //        "avatar": 5,
            //        anagramData: {
            //            answer: "answer",
            //            score: 1,
            //            ready: true,
            //            showAnswer: true,
            //            showValidation: true,
            //            isCorrect: false,
            //            answerHistory: [
            //                "Chocolate"]
            //        },
            //        showPlayer: true,
            //    },
            //    {
            //        "name": "WWWWWWWWWW",
            //        "id": "6",
            //        "avatar": 6,
            //        anagramData: {
            //            answer: "answer",
            //            score: 2,
            //            ready: true,
            //            showAnswer: true,
            //            showValidation: false,
            //            isCorrect: false,
            //            answerHistory: [
            //                "supermans"]
            //        },
            //        showPlayer: true,
            //    },
            //    {
            //        "name": "Pla",
            //        "id": "7",
            //        "avatar": 7,
            //        anagramData: {
            //            answer: "answer",
            //            score: 2,
            //            ready: true,
            //            showAnswer: true,
            //            showValidation: false,
            //            isCorrect: false,
            //            answerHistory: [
            //                "banana"]
            //        },
            //        showPlayer: true,
            //    },
            //    {
            //        "name": "Pla",
            //        "id": "7",
            //        "avatar": 8,
            //        anagramData: {
            //            answer: "answer",
            //            score: 2,
            //            ready: true,
            //            showAnswer: true,
            //            showValidation: false,
            //            isCorrect: false,
            //            answerHistory: [
            //                "banana"]
            //        },
            //        showPlayer: true,
            //    },
            //],
            playersRequired: 1,
            showPlayers: true,
            gameState: GameStates.Loading,

            doingTutorial: false,
            showTutorial: false,
            //showTutorial: true,
            skipTutorialCount: 0,

            timerText: 0,
            showTimer: false,
            //showTimer: true,

            showStartButtons: true,
            //showStartButtons: false, //debug
            showPlayAgainButtons: false,

            roundNumber: 0,
            currentAnagram: "",
            targetAnagram: "TESTXTEST",
            bonusSubmitions: [],

            showRoundNumber: false,
            showRoundNumberRays: false,
            showInfoBox: false,
            infoBoxText: "",
            showPlayerSelecting: false,
            playerSelectingId: "",
            currentSelected: [],
            showLetters: [],

            showWinners: false,
            hideBonusRoundWinners: false,
            showBonusAnagram: false,
            //showWinners: true,

            //doConfetti: false,

            roundType: "",
        };
        this.toggleMute = this.toggleMute.bind(this);
        this.toggleMenu = this.toggleMenu.bind(this);
    }

    componentDidMount() {
        this.setTags();
        this.doReconnect();

        //this.toggleMute(true, false);
        this.initAudio();
    }

    initAudio() {
        console.log("Init audio");
        this.preloadAudio();
        Howler.volume(0.5);
        this.playAudio(audio.BgMusic);
    }

    preloadAudio() {
        for (let key in audio) {
            audio[key].loaded = new Howl({
                src: [audio[key].import],
                preload: true,
                loop: audio[key].loop,
                volume: audio[key].volume
            });
        }
    }

    setTags() {
        const token = this.getQueryStringValue('token');
        Sentry.setTag('isHost', true);

        if (token) {
            const [roomId, reconnectToken] = token.split(':');
            Sentry.setTag('roomId', roomId);
            Sentry.setTag('reconnectToken', reconnectToken);
        }
    }

    playAudio(audio) {
        if (audio && audio.loaded) audio.loaded.play();
    }

    toggleFullScreen() {
        if (fullscreenAvailable) {
            if (!document.fullscreenElement && !document.webkitIsFullScreen && !document.mozFullScreen && !document.msFullscreenElement) {
                let elem = document.documentElement
                if (elem.requestFullscreen) {
                    elem.requestFullscreen();
                } else if (elem.webkitRequestFullscreen) {
                    elem.webkitRequestFullscreen();
                } else if (elem.mozRequestFullScreen) {
                    elem.mozRequestFullScreen();
                } else if (elem.msRequestFullscreen) {
                    elem.msRequestFullscreen();
                }
            } else {
                if (document.exitFullscreen) {
                    document.exitFullscreen();
                } else if (document.webkitExitFullscreen) {
                    document.webkitExitFullscreen();
                } else if (document.mozExitFullscreen) {
                    document.mozExitFullscreen();
                } else if (document.msExitFullscreen) {
                    document.msExitFullscreen();
                }
            }
        }
    }

    getQueryStringValue(key) {
        return decodeURIComponent(window.location.search.replace(new RegExp("^(?:.*[&\\?]" + encodeURIComponent(key).replace(/[.+*]/g, "\\$&") + "(?:\\=([^&]*))?)?.*$", "i"), "$1"));
    }

    animatePotato(id, animation) {
        if (document.getElementById(`potato-${id}`)) {
            this.animateCSS(`#potato-${id}`, animation);
        }
    }

    animateCSS = (element, animation, prefix = 'animate__') =>
        // We create a Promise and return it
        new Promise((resolve, reject) => {
            const animationName = `${prefix}${animation}`;
            let node;
            if (typeof element === `string`) {
                node = document.querySelector(element);
            } else {
                node = element;
            }
            node.classList.add(`${prefix}animated`, animationName);

            // When the animation ends, we clean the classes and resolve the Promise
            function handleAnimationEnd(event) {
                event.stopPropagation();
                node.classList.remove(`${prefix}animated`, animationName);
                resolve('Animation ended');
            }

            node.addEventListener('animationend', handleAnimationEnd, { once: true });
        });

    toggleMenu() {
        console.log("toggle menu");
        let newVal = this.state.menuOpen === true ? false : true;
        this.setState({ menuOpen: newVal });
    }

    toggleMute(force = false, value = false) {
        let newVal;
        if (force) {
            newVal = value;
        } else {
            newVal = !this.state.muted;
        }

        Howler.mute(newVal);
        this.setState({ muted: newVal, });
    }

    checkAndAddPlayer(player) {
        if (!this.state.players.find(elem => elem.id === player.id)) {
            this.setState((prevState) => {
                return { players: [...prevState.players, player] }
            });
            setTimeout(() => { // animate in timeout
                let statePlayers = [...this.state.players];
                let pos = statePlayers.map(function (e) { return e.id; }).indexOf(player.id);
                let statePlayer = { ...statePlayers[pos] };
                statePlayer.showPlayer = true;
                statePlayers[pos] = statePlayer;
                this.setState({ players: statePlayers });
            }, 500);
        }
    }

    removePlayer(id) {
        this.setState((prevState) => {
            return { players: prevState.players.filter(x => x.id !== id), }
        });
    }

    getRandomElement(array) {
        return array[Math.random() * array.length >> 0];
    }

    signalStartGame = () => {
        this.playAudio(audio.Click);
        this.setState({ showStartButtons: false });
        //this.state.room.send("begin_game", { skipTutorial: true, });
        this.state.room.send("begin_game", { skipTutorial: this.state.tickedSkipTutorial, });
    }
    goToLobby = () => {
        this.playAudio(audio.Click);
        this.state.room.send("change_game", {});
    }

    signalNewGame = () => {
        this.playAudio(audio.Click);
        this.setState({ showPlayAgainButtons: false, });
        setTimeout(() => {
            this.state.room.send("anagram_new_game", {});
        }, 1000);
    }

    getRedirectURL(display = false) {
        let url = display ? process.env.REACT_APP_GAME_CITY_URL_DISPLAY : process.env.REACT_APP_GAME_CITY_URL;
        if (this.state.room) {
            if (this.state.room.name !== "game_city_room") {
                url = display ? process.env.REACT_APP_HOME_URL_DISPLAY : process.env.REACT_APP_HOME_URL;
            }
        }
        return url;
    }

    toggleSkipTutorial = (e) => {
        if (this.state.showStartButtons) {
            console.log("cb value: " + e.target.checked);
            this.setState({ tickedSkipTutorial: e.target.checked });
        }
    }

    hideTimer() {
        this.setState({ showTimer: false, });
        //if (audio.Timer5SecondsClock.loaded) audio.Timer5SecondsClock.loaded.stop();
        this.playAudio(audio.RoundOver);
    }

    getRoundCountImg = () => {
        const roundImgs = [Round1Img, Round2Img, Round3Img];
        return roundImgs[this.state.roundNumber - 1];
    }

    shuffle(string) {
        return string.split('')
            .sort(() => 0.5 - Math.random())
            .join('');
    }

    async doStartRound(message) {
        this.setState({ playerSelectingId: message.playerSelectingId, currentSelected: [], roundNumber: message.roundNumber, showPlayers: true, currentAnagram: "", showLetters: [] });
        let playerSelecting = this.state.players.find((x) => x.id === message.playerSelectingId);
        await this.sleep(750);
        this.setState({ showRoundNumberRays: true });
        await this.sleep(500);
        this.playAudio(audio.Woosh);
        this.setState({ showRoundNumber: true, });
        await this.sleep(5000);
        this.playAudio(audio.Woosh);
        this.setState({ showRoundNumber: false });
        await this.sleep(500);
        this.setState({ showRoundNumberRays: false, });
        await this.sleep(2000);
        this.playAudio(audio.ItemPopup);
        this.setState({ showInfoBox: true, infoBoxText: `${playerSelecting.name} select the letters!`, showPlayerSelecting: true });
        await this.sleep(2000);
        this.state.room.send("round_started", {});
    }

    sleep(time) {
        return new Promise((resolve) => setTimeout(resolve, time));
    }

    async doStartBonusRound() {
        this.playAudio(audio.Woosh);
        this.setState({ hideBonusRoundWinners: false, showRoundNumberRays: true, roundType: "bonus" });
        await this.sleep(500);
        this.setState({ showRoundNumber: true });
        await this.sleep(5000);
        this.setState({ showRoundNumber: false });
        this.playAudio(audio.Woosh);
        await this.sleep(500);
        this.setState({ showRoundNumberRays: false, });
        await this.sleep(2000);
        this.playAudio(audio.ItemPopup);
        this.setState({ showInfoBox: true, infoBoxText: "Find the 9 letter anagram!" });
        await this.sleep(1000);
        // loop through show letters and set them true;
        let showLetters = [...this.state.showLetters];
        for (var i = 0; i < 9; i++) {
            showLetters[i] = true;
            this.setState({ showLetters: showLetters })
            await this.sleep(150);
        }
        await this.sleep(1000);
        this.state.room.send("bonus_round_started", {});
    }

    newLetter(letter, type) {
        let showLetters = [...this.state.showLetters];
        let currentAnagram = this.state.currentAnagram;
        currentAnagram += letter;
        showLetters[currentAnagram.length - 1] = true;
        this.setState({ showLetters, currentAnagram, });
        this.playAudio(audio.Pop);
        if (type) {
            let currentSelected = [...this.state.currentSelected];
            currentSelected.push(<div key={`letter-${this.state.currentAnagram.length}`} className={styles.letterCallout} style={{ "--offset": `${Math.random() * 4 - 2}vh` }}>{type}</div>);
            this.setState({ currentSelected });
            setTimeout(() => {
                currentSelected = [...this.state.currentSelected];
                currentSelected.shift();
                this.setState({ currentSelected });
            }, 2000);
        }
    }

    async doSelectingEnded() {
        this.setState({ showPlayerSelecting: false });
        await this.sleep(1500);
        this.setState({ showInfoBox: false, });
        await this.sleep(1000);
        this.playAudio(audio.ItemPopup);
        this.setState({ infoBoxText: "Find the longest word you can!", showInfoBox: true, });
        await this.sleep(1000);
        this.state.room.send("start_answering", {});
    }

    async doShowAnswers() {
        this.hideTimer();

        await this.sleep(1500);
        let statePlayers = [...this.state.players];
        for (let i = 0; i < statePlayers.length; i++) {
            if (i % 2 === 0) {
                await this.sleep(500);
                let statePlayer = { ...statePlayers[i] };
                statePlayer.anagramData.showAnswer = true;
                statePlayers[i] = statePlayer;
                this.playAudio(audio.ItemPopup);
                this.setState({ players: statePlayers });
            }
        }

        await this.sleep(500);
        for (let i = 0; i < statePlayers.length; i++) {
            if (i % 2 === 1) {
                await this.sleep(500);
                let statePlayer = { ...statePlayers[i] };
                statePlayer.anagramData.showAnswer = true;
                this.playAudio(audio.ItemPopup);
                statePlayers[i] = statePlayer;
                this.setState({ players: statePlayers });
            }
        }

        await this.sleep(3000);
        for (let i = 0; i < statePlayers.length; i++) {
            let statePlayer = { ...statePlayers[i] };
            statePlayer.anagramData.showValidation = true;
            //this.playAudio(statePlayer.anagramData.isCorrect ? audio.Correct : audio.Incorrect);
            statePlayers[i] = statePlayer;
        }
        this.setState({ players: statePlayers });

        await this.sleep(5000);
        for (let i = 0; i < statePlayers.length; i++) {
            let statePlayer = { ...statePlayers[i] };
            statePlayer.anagramData.showAnswer = false;
            //this.playAudio(statePlayer.anagramData.isCorrect ? audio.Correct : audio.Incorrect);
            statePlayers[i] = statePlayer;
        }
        this.setState({ players: statePlayers });

        await this.sleep(1500);
        this.state.room.send("validate_answers", {});
    }

    async doEndBonusAnswer() {
        this.hideTimer();
        this.setState({ showInfoBox: false, });
        await this.sleep(500);
        let showLetters = [...this.state.showLetters];
        for (let i = 0; i < this.state.currentAnagram.length; i++) {
            showLetters[i] = false;
            this.setState({ showLetters });
            await this.sleep(100);
        }
        this.hidePlayers();
        await this.sleep(1000);
        this.setState({ showInfoBox: true, infoBoxText: "The answer was..." });
        this.playAudio(audio.DrumRoll);
        await this.sleep(2000);
        this.setState({ showBonusAnagram: true });
        //this.playAudio(audio.Reveal);
        await this.sleep(5000);
        this.setState({ bonusSubmitions: [], showBonusAnagram: false, showInfoBox: false, hideBonusRoundWinners: true });
        await this.sleep(1000);
        this.state.room.send("start_round"); // calls the servers round logic (this will end the game if there are no more bonus rounds)
    }

    hidePlayers() {
        let statePlayers = [...this.state.players];
        for (let i = 0; i < statePlayers.length; i++) {
            let statePlayer = { ...statePlayers[i] };
            statePlayer.anagramData.showValidation = false;
            statePlayer.anagramData.showAnswer = false;
            statePlayers[i] = statePlayer;
        }
        this.setState({ players: statePlayers });
    }

    doEndRound() {
        setTimeout(() => {
            this.setState({ showInfoBox: false, });
            let showLetters = [...this.state.showLetters];
            let waitTime = 0;
            for (let i = 0; i < this.state.currentAnagram.length; i++) {
                setTimeout(() => {
                    showLetters[i] = false;
                    this.setState({ showLetters });
                }, (250 * i));
                waitTime += (100 * i);
            }
            this.hidePlayers();

            setTimeout(() => {
                this.state.room.send("start_round"); // calls the servers round logic (this will start the bonus rounds if there are no more rounds)
            }, (waitTime + 1000));
        }, 3000);
    }

    doGameOver() {

        setTimeout(() => {
            this.setState({ showWinners: true, showPlayers: false, });
            this.playAudio(audio.Fanfare);
            setTimeout(() => {
                this.setState({ showWinners: false });

                setTimeout(() => {
                    this.state.room.send("reached_end", {});
                    this.setState({ showPlayAgainButtons: true, });
                }, 2000);
            }, 5000);
        }, 2000);
    }

    getWinners() {
        let statePlayers = [...this.state.players];
        let winners = [];
        let highestScore = 0;
        for (let i = 0; i < statePlayers.length; i++) {
            if (statePlayers[i].anagramData.score > highestScore) {
                highestScore = statePlayers[i].anagramData.score;
            }
        }

        for (let i = 0; i < statePlayers.length; i++) {
            if (statePlayers[i].anagramData.score === highestScore) {
                winners.push(statePlayers[i]);
            }
        }

        return winners;
    }

    getSortedPlayersByScore() {
        let statePlayers = [...this.state.players];
        statePlayers.sort((a, b) => {
            return b.anagramData.score - a.anagramData.score;
        });
        let lastIndex = 1;
        return <React.Fragment>
            {
                statePlayers.map((x, index, arr) => {
                    if (index > 0 && x.anagramData.score < arr[index - 1].anagramData.score) lastIndex++;
                    return <div key={`row-${index}`} className={`${styles.tableRow} ${styles.player}`}>
                        <div className={styles.position}>{lastIndex}</div>
                        <div className={styles.name}>{x.name}</div>
                        <div className={styles.score}>{x.anagramData.score}<span className={styles.smaller}>pts</span></div>
                        <div className={styles.potato}>
                            <Lottie
                                options={getAvatarById(x.avatar).idleAnim}
                                width="100%"
                                height="100%"
                                isClickToPauseDisabled={true}
                            />
                        </div>
                    </div>
                })
            }
        </React.Fragment>
    }

    showBonusRoundSubmitions() {
        const first = this.state.players.find((p) => p.id === this.state.bonusSubmitions[0]);
        const second = this.state.players.find((p) => p.id === this.state.bonusSubmitions[1]);
        const third = this.state.players.find((p) => p.id === this.state.bonusSubmitions[2]);

        console.log(first);
        return <div className={`${styles.podium} ${styles.show}`}>
            {second ? this.podiumPosition(second, "2nd") : <div className={`${styles.potato}`}></div>}
            {first ? this.podiumPosition(first, "1st", styles.first) : <div className={`${styles.potato}`}></div>}
            {third ? this.podiumPosition(third, "3rd") : <div className={`${styles.potato}`}></div>}
        </div>
    }

    showBonusAnagram() {
        return <div className={`${styles.bonusAnswer} ${this.state.showBonusAnagram && styles.show}`}>
            <div className={`${styles.raysContainer} ${styles.show}`}>
                <img src={RaysImg} className={`${styles.rays} ${styles.show}`} alt="rays" />
            </div>
            <div className={`${styles.bonusAnswerBox}`} >
                <p className={styles.bonusAnswerText} >{this.state.targetAnagram.toUpperCase()}</p>
            </div>
        </div>
    }

    podiumPosition(player, pos, style) {
        return (
            < div className={`${styles.potato} ${style} ${this.state.hideBonusRoundWinners ? styles.hide : styles.raise}`}>
                <div className={`${styles.nameBox}`}>
                    <div className={`${styles.name}`}>
                        {player.name}
                    </div>
                    {/*<div className={`${styles.place}`}>*/}
                    {/*    <div className={`${styles.name}`}>*/}
                    {/*        {pos}*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </div>
                <Lottie
                    options={getAvatarById(player.avatar).idleAnim}
                    width="100%"
                    height="100%"
                    isClickToPauseDisabled={true}
                />
            </div >
        )
    }


    getLongestWordList() {
        const longestWords = this.getLongestWords();
        let items = [];

        for (let i = 0; i < longestWords.length; i++) {
            let item = longestWords[i];
            items.push(
                <div className={styles.wordRow}>
                    <div className={styles.potato}>
                        <Lottie
                            options={getAvatarById(item.avatar).idleAnim}
                            width="100%"
                            height="100%"
                            isClickToPauseDisabled={true}
                        />
                    </div>
                    <div className={styles.nameBox}>
                        <img src={WordBanner} className={styles.nameBanner} alt="word-banner" />
                        <div className={styles.name}>{item.name}</div>
                    </div>
                    <div className={styles.word}>{item.word}</div>
                </div>
            )
        }
        return items;
    }

    getLongestWords() {
        const limit = 4
        let statePlayers = [...this.state.players];
        const allWords = [];

        for (const player of statePlayers) {
            const name = player.name;
            const avatar = player.avatar;
            const answerHistory = player.anagramData.answerHistory;

            allWords.push(...answerHistory.map(word => ({ name, word, avatar })));
        }

        allWords.sort((a, b) => b.word.length - a.word.length);

        const longestWords = allWords.slice(0, limit);

        return longestWords;
    }

    getAnagramDisplay() {
        let elements = []
        let letters = this.state.currentAnagram.split("");
        for (let i = 0; i < 9; i++) {
            const letter = letters[i];
            elements.push(<div key={`letter-${i}`} className={`${styles.anagramLetter} ${this.state.showLetters[i] && styles.show} ${this.state.roundType === "standard" && bonusLetters.test(letter) && styles.gold}`}>
                {/*<img src={bonusLetters.test(letter) ? GoldWordTileImg : WordTileImg} className={styles.wordTile} alt="word-tile" />*/}
                <p className={styles.letterText} >{letter}</p>
            </div>);
        }
        return elements;
    }

    updateToken(token) {
        var url = new URL(window.location.href);

        try {
            window.history.replaceState(null, null, (url.pathname) + (`?token=${token}`));
        } catch (e) {
            console.warn(e)
        }
    }

    doReconnect = () => {
        if (this.state.reconnectTries < 5) {
            //const roomId = this.getQueryStringValue("roomId");
            //const sessionId = this.getQueryStringValue("sessionId");
            const token = this.getQueryStringValue("token");
            //console.log(roomId);

            if (this.state.connected === false) {
                this.client.reconnect(token).then(room => {
                    console.log(room.sessionId, "joined", room.name);
                    this.setState({ room: room, roomId: room.id, myId: room.sessionId, connected: true, reconnectionToken: room.reconnectionToken });
                    this.updateToken(room.reconnectionToken);
                    room.send("update_host_token", { reconnectionToken: room.reconnectionToken });


                    room.onStateChange.once((state) => {
                        console.log("this is the first room state!", state);
                        if (state.host.id !== room.sessionId) window.location = this.getRedirectURL();
                        Sentry.setUser({ id: state.host.uniqueId });
                        this.setState({ roomState: state, });

                        // this casues everyone to go back to lobby if the host has refreshed their page mid game
                        if (state.anagramData.gameState === GameStates.Loading) {
                            room.send("host_joined_game", { gameId });
                        } else {
                            room.send("change_game", {});
                        }

                        room.state.anagramData.bonusSubmitions.onChange((change, index) => {
                            console.log("bonusSubmitions change ", change, "index", index);
                            let bonusSubmitions = [...this.state.bonusSubmitions];
                            if (index + 1 > bonusSubmitions.length) this.playAudio(audio.Correct);
                            bonusSubmitions[index] = change;
                            bonusSubmitions = bonusSubmitions.filter(n => n);
                            this.setState({ bonusSubmitions: bonusSubmitions });
                        });

                        room.state.anagramData.listen("roundType", (value) => {
                            console.log("roundType chenged to ", value);
                            this.setState({ roundType: value });
                        });

                        room.state.anagramData.listen("currentAnagram", (value) => {
                            console.log("currentAnagram chenged to ", value);
                            this.setState({ currentAnagram: value });
                        });

                        room.state.anagramData.listen("targetAnagram", (value) => {
                            console.log("targetAnagram chenged to ", value);
                            this.setState({ targetAnagram: value });
                        });
                    });

                    room.onStateChange((state) => {
                        console.log(room.name, "has new state:", state);
                        this.setState({ roomState: state, });
                    });

                    room.state.players.onAdd((player, key) => {
                        console.log(player, "has been added at", key);
                        this.checkAndAddPlayer(player);

                        const changes = ["connected", "votedSkip"];
                        changes.forEach(change => {
                            player.listen(change, (value) => {
                                let statePlayers = [...this.state.players];
                                let pos = statePlayers.map(function (e) { return e.id; }).indexOf(player.id);
                                let statePlayer = { ...statePlayers[pos] };

                                statePlayer[change] = value;

                                statePlayers[pos] = statePlayer;
                                this.setState({ players: statePlayers });
                            });
                        });

                        const anagramPlayerChanges = ["score", "answer", "ready", "isCorrect", "earnedPoints"];
                        anagramPlayerChanges.forEach(change => {
                            player.anagramData.listen(change, (value, previousValue) => {
                                let statePlayers = [...this.state.players];
                                let pos = statePlayers.map(function (e) { return e.id; }).indexOf(player.id);
                                let statePlayer = { ...statePlayers[pos] };

                                if (value && change === "score" && value > previousValue)
                                    this.playAudio(audio.Correct);

                                if (change === "ready" && value && !previousValue)
                                    this.playAudio(audio.Ready);

                                statePlayer.anagramData[change] = value;

                                statePlayers[pos] = statePlayer;
                                this.setState({ players: statePlayers });
                            });
                        });

                        player.anagramData.answerHistory.onChange((change, index) => {
                            console.log("change ", change, "index", index);
                            let statePlayers = [...this.state.players];
                            let pos = statePlayers.map(function (e) { return e.id; }).indexOf(player.id);
                            let statePlayer = { ...statePlayers[pos] };
                            statePlayer.anagramData.answerHistory[index] = change;

                            statePlayers[pos] = statePlayer;
                            this.setState({ players: statePlayers });
                        });

                    });

                    room.state.players.onRemove((player, key) => {
                        console.log(player, "has been removed at", key);
                        this.removePlayer(player.id);
                    });

                    room.onMessage("animate_potato", (message) => {
                        console.log("animate_potato", "received on", room.name, message);
                        this.animatePotato(message.id, message.animation);
                    });

                    room.onMessage("begin_tutorial", (message) => {
                        console.log("begin_tutorial", "received on", room.name, message);
                        this.setState({ showTutorial: true, showStartButtons: false, showPlayers: false, });
                        this.state.room.send("show_tutorial", {});
                    });

                    room.onMessage("end_tutorial", (message) => {
                        console.log("end_tutorial", "received on", room.name, message);
                        this.setState({ showTutorial: false, showPlayers: true, });
                    });

                    room.onMessage("clicked_begin_game", (message) => {
                        this.setState({ showStartButtons: false, });
                    });

                    room.onMessage("begin_game", (message) => {
                        console.log("begin_game", "received on", room.name, message);
                        console.log("game state... ", this.state.roomState.anagramData.gameState);
                        if ((this.state.roomState.anagramData.gameState === GameStates.Loading || this.state.roomState.anagramData.gameState === GameStates.Idle) && !this.state.gameBegun) {
                            this.setState({ showTutorial: false, gameBegun: true, showStartButtons: false, });
                            room.send("start_round", {});
                        }
                    });

                    room.onMessage("start_round", (message) => {
                        console.log("start_round", "received on", room.name, message);
                        this.doStartRound(message);
                    });

                    room.onMessage("start_bonus_round", (message) => {
                        console.log("start_bonus_round", "received on", room.name, message);
                        this.doStartBonusRound(message);
                    });

                    room.onMessage("new_letter", (message) => {
                        console.log("new_letter", "received on", room.name, message);
                        this.newLetter(message.letter, message.type);
                    });

                    room.onMessage("selecting_ended", (message) => {
                        console.log("selecting_ended", "received on", room.name, message);
                        this.doSelectingEnded();
                    });

                    room.onMessage("show_answers", (message) => {
                        console.log("show_answers", "received on", room.name, message);
                        this.doShowAnswers();
                    });

                    room.onMessage("show_bonus_answers", (message) => {
                        console.log("show_bonus_answers", "received on", room.name, message);
                        this.doEndBonusAnswer();
                    });

                    room.onMessage("end_round", (message) => {
                        console.log("end_round", "received on", room.name, message);
                        this.doEndRound();
                    });

                    room.onMessage("game_over", (message) => {
                        console.log("game_over", "received on", room.name, message);
                        this.doGameOver();
                    });

                    room.onMessage("change_game", (message) => {
                        console.log("change_game", "received on", room.name, message);
                        this.setState({ redirect: `${this.getRedirectURL()}/lobby/?token=${this.state.reconnectionToken}` });
                        this.state.room.leave(false);
                    });

                    room.onMessage("new_game", (message) => {
                        console.log("new_game", "received on", room.name, message);
                        this.setState({ showPlayAgainButtons: false, });
                        this.state.room.send("start_round", {});
                    });

                    room.onMessage("update_timer", (message) => {
                        console.log("update_timer", "received on", room.name, message);
                        this.setState({ showTimer: true, });
                        if (message.count <= 5) {
                            this.playAudio(audio.ClockTick);
                            if (message.count === 5) {
                                //this.playAudio(audio.Timer5SecondsClock);
                            }
                            if (message.count <= 0) {
                                this.hideTimer();
                            }
                        }
                        this.setState({ timerText: message.count, });
                    });

                    room.onError((code, message) => {
                        Sentry.captureMessage(`WS Received: onError; message: ${message}`);
                        console.log(this.client.id, "couldn't join", room.name);
                        //LoggingService.logError(message, code);
                    });
                    room.onLeave((code) => {
                        console.log(this.client.id, "left", room.name);
                        if (!this.state.redirect) {
                            this.setState({ connected: false, reconnectTries: this.state.reconnectTries + 1 });
                            setTimeout(() => {
                                this.doReconnect();
                            }, 1000);
                        }
                    });
                }).catch(e => {
                    console.log("JOIN ERROR", e);
                    this.setState({ connected: false, reconnectTries: this.state.reconnectTries + 1 });
                    const message = e.message ? e.message : "An error occured Hosting Anagram Magic.";
                    //LoggingService.logError(message, e);
                    if (!message.includes("has been disposed")) Sentry.captureMessage(`doReconnect Error: ${message}`);
                    setTimeout(() => {
                        this.doReconnect();
                    }, 1000);
                });
            }
        } else {
            this.setState({ redirect: `${this.getRedirectURL()}/` });
        }
    }

    render() {

        if (this.state.redirect) {
            return (
                <React.Fragment>
                    <div id="gameContainer" className={styles.gameContainer}>
                        <div className={styles.loadingContainer}>
                            <Loading loadingText={"Sending you to the lobby!"} />
                        </div>
                    </div>
                    <div style={{ opacity: 0 }}>
                        <Route path="/" render={() => (window.location = this.state.redirect)} />
                    </div>'
                </React.Fragment>
            )
        }

        let playerSelecting = this.state.players.find((x) => x.id === this.state.playerSelectingId);
        return (
            <div>
                <audio ref />
                <div id="gameContainer" className={styles.gameContainer}>
                    {
                        !this.state.connected ?
                            <div className={styles.logoSection}>
                                <img src={logo} className={styles.logo} alt="logo" />
                            </div>
                            :
                            <React.Fragment>
                                <Menu room={this.state.room} toggleMute={this.toggleMute} toggleMenu={this.toggleMenu} menuOpen={this.state.menuOpen} muted={this.state.muted} />
                                {
                                    this.state.showTutorial &&
                                    <Tutorial room={this.state.room} players={this.state.players} />
                                }
                                {/*<div style={{ zIndex: 20, }}>*/}
                                {/*    {*/}
                                {/*        this.state.doConfetti &&*/}
                                {/*        <Confetti*/}
                                {/*            width={window.innerWidth}*/}
                                {/*            height={window.innerHeight}*/}
                                {/*            initialVelocityY={20}*/}
                                {/*            numberOfPieces={1500}*/}
                                {/*            recycle={false}*/}
                                {/*            confettiSource={{ x: window.innerWidth / 2, y: window.innerHeight + 10, width: window.innerWidth + 10, height: 0 }}*/}
                                {/*            initialVelocityY={{ min: -10, max: -30, }}*/}
                                {/*            initialVelocityX={{ min: -10, max: 10, }}*/}
                                {/*            onConfettiComplete={() => this.setState({ doConfetti: false, })}*/}
                                {/*        />*/}
                                {/*    }*/}
                                {/*</div>*/}
                                <div className={styles.roomCode}>
                                    <div className={styles.textBox}>
                                        <div className={styles.text}>{this.getRedirectURL(true)}</div>
                                        <div className={styles.text}>Code: <span className={`${styles.text} ${styles.code}`}>{this.state.roomId}</span></div>
                                        <div className={styles.iconsBox}>
                                            <div className={styles.muteToggle} onClick={() => this.toggleMute()}>
                                                <img src={this.state.muted ? mutedIcon : unmutedIcon} className={styles.muteIcon} alt="muted" />
                                            </div>
                                            {
                                                fullscreenAvailable ?
                                                    <div className={styles.muteToggle} onClick={() => { this.toggleFullScreen() }}>
                                                        <img src={fullscreenIcon} className={styles.muteIcon} alt="fullscreen" />
                                                    </div>
                                                    :
                                                    null
                                            }
                                            <div className={styles.muteToggle} onClick={() => this.toggleMenu()}>
                                                <img src={helpIcon} className={styles.muteIcon} alt="help" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className={styles.qrCodeBox}>
                                        <QRCode
                                            className={styles.qrCode}
                                            value={`${this.getRedirectURL()}/play/?qrCode=${this.state.roomId}`}
                                            bgColor="rgba(0,0,0,0)"
                                        />
                                    </div>
                                </div>
                                <div className={`${styles.logoSection} ${this.state.connected ? styles.topLeft : ""}`}>
                                    <img src={logo} className={`${styles.logo}`} alt="logo" />
                                </div>
                                {
                                    this.state.showStartButtons &&
                                    <div className={styles.startButtonSection}>
                                        <button className={`${styles.mainButton} ${styles.alt}`} onClick={this.signalStartGame}>
                                            Start Game
                                        </button>
                                        <button className={`${styles.mainButton} ${styles.smaller}`} onClick={this.goToLobby}>
                                            Go To Lobby
                                        </button>
                                        <div className={styles.skipBox}>
                                            <input className={styles.checkbox} type="checkbox" id="checkbox" name="checkbox" onChange={this.toggleSkipTutorial} />
                                            <label htmlFor="checkbox">Skip Tutorial</label>
                                        </div>
                                    </div>
                                }
                                <div className={`${styles.playerColumn} ${styles.left} ${this.state.showPlayers && styles.show}`}>
                                    {
                                        this.state.players.map((x, index) => {
                                            return index % 2 === 0 ? <Player key={`player-${index}`} player={x} left={true} room={this.state.room} /> : ""
                                        })
                                    }
                                </div>
                                <div className={`${styles.playerColumn} ${styles.right} ${this.state.showPlayers && styles.show}`}>
                                    {
                                        this.state.players.map((x, index) => {
                                            return index % 2 === 1 ? <Player key = {`player-${index}`}  player={x} room={this.state.room} /> : ""
                                        })
                                    }
                                </div>
                                {
                                    this.state.showPlayAgainButtons &&
                                    <div className={styles.playAgainButtonSection}>
                                        <button className={`${styles.button} ${styles.alt}`} onClick={this.signalNewGame}>
                                            Play Again
                                        </button>
                                        <button className={`${styles.button}`} onClick={this.goToLobby}>
                                            Return to lobby
                                        </button>
                                    </div>
                                }
                                <div className={`${styles.roundNumberSection}`}>
                                    <div className={`${styles.raysContainer} ${this.state.showRoundNumberRays && styles.show}`}>
                                        <img src={RaysImg} className={`${styles.rays} ${styles.show}`} alt="rays" />
                                    </div>
                                    <div className={`${styles.roundTextContainer} ${this.state.showRoundNumber && styles.show}`}>
                                        <div className={`${styles.roundText}`}>
                                            <p className={`${styles.gradiant} ${this.state.roundType === "bonus" && styles.gold}`}>{this.state.roundType === "bonus" ? "BONUS ROUND" : "ROUND"}</p>
                                            {this.state.roundType === "standard" && <div className={styles.circle}>
                                                <div className={styles.innerCircle}>
                                                    <p className={styles.number} >{this.state.roundNumber}</p>
                                                </div>
                                            </div>}
                                        </div>
                                    </div>
                                    {/*<img src={this.getRoundCountImg()} className={styles.roundNumber} alt="round-count" />*/}
                                </div>
                                <div className={`${styles.anagramSection}`}>
                                    <div className={`${styles.infoBox} ${this.state.showInfoBox && styles.show}`}>
                                        <div className={styles.background} />
                                        <div className={styles.innerText}>{this.state.infoBoxText}</div>
                                    </div>
                                    <div className={styles.anagram}>
                                        {/*{*/}
                                        {/*    this.state.currentAnagram.split('').map((x, index) => {*/}
                                        {/*        return <div className={`${styles.anagramLetter} ${this.state.showLetters[index] && styles.show}`}>*/}
                                        {/*            <img src={WordTileImg} className={styles.wordTile} />*/}
                                        {/*            <div className={styles.letter}>{x}</div>*/}
                                        {/*        </div>*/}
                                        {/*    })*/}
                                        {/*}*/}
                                        {
                                            this.getAnagramDisplay()
                                        }
                                    </div>
                                    <div className={`${styles.playerSelecting} ${this.state.roundType === "standard" && this.state.showPlayerSelecting && styles.show}`}>
                                        {
                                            playerSelecting != null &&
                                            <React.Fragment>
                                                <div className={`${styles.potato}`}>
                                                    <Lottie
                                                        options={getAvatarById(playerSelecting.avatar).idleAnim}
                                                        width="100%"
                                                        height="100%"
                                                        isClickToPauseDisabled={true}
                                                    />
                                                </div>
                                                <div className={`${styles.textSection}`}>
                                                    {this.state.currentSelected}
                                                </div>
                                            </React.Fragment>
                                        }
                                    </div>
                                    {this.state.roundType === "bonus" && this.showBonusRoundSubmitions()}
                                    {this.showBonusAnagram()}
                                </div>
                                <div className={`${styles.winnerSection} ${this.state.showWinners && styles.show}`}>
                                    <img src={WinnersTitle} className={styles.winnersTitle} alt="winners-title" />
                                    <div className={`${styles.raysContainer} ${styles.show}`}>
                                        <img src={RaysImg} className={`${styles.rays} ${styles.show}`} alt="rays" />
                                    </div>
                                    <div className={styles.winnerList}>
                                        {
                                            this.getWinners().map((x, index, arr) => {
                                                let size = [1, 2].includes(arr.length) ? "large" : [3, 4].includes(arr.length) ? "medium" : "small";
                                                return <div key={`winner-${index}`} className={`${styles.winner} ${size === "large" ? styles.large : size === "medium" ? styles.medium : styles.small}`}>
                                                    <div className={`${styles.potato}`}>
                                                        <img className={styles.winnerOverlay} src={WinnerBanner} alt="winners-overlay" />
                                                        <div className={`${styles.nameBox}`}>
                                                            <div className={`${styles.name}`}>
                                                                {x.name}
                                                            </div>
                                                        </div>
                                                        <Lottie
                                                            options={getAvatarById(x.avatar).idleAnim}
                                                            width="100%"
                                                            height="100%"
                                                            isClickToPauseDisabled={true}
                                                        />
                                                    </div>
                                                </div>
                                            })
                                        }
                                    </div>
                                </div>
                                <div className={`${styles.gameOverSection}`}>
                                    <div className={`${styles.longestWordsSection} ${this.state.showPlayAgainButtons && styles.show}`}>
                                        <img src={LongestWordsTitle} className={styles.title} alt="longest-word-title" />
                                        <div className={styles.wordList}>
                                            {
                                                this.getLongestWordList()
                                            }
                                        </div>
                                    </div>
                                    <div className={`${styles.leaderboardSection} ${this.state.showPlayAgainButtons && styles.show}`}>
                                        <div className={`${styles.tableRow} ${styles.header}`}>
                                            <div className={styles.position}>position</div>
                                            <div className={styles.name}>player</div>
                                            <div className={styles.score}>score</div>
                                        </div>
                                        {
                                            this.getSortedPlayersByScore()
                                        }
                                    </div>
                                </div>
                                <div className={`${styles.timerSection} ${this.state.showTimer && styles.show}`}>
                                    <img src={ClockBg} className={styles.clockBg} alt="clock-bg" />
                                    <div className={styles.clock}>
                                        <img src={ClockImg} className={styles.clockImg} alt="clock" />
                                        <img src={ClockHandImg} className={styles.clockHand} alt="clock-hand" />
                                    </div>
                                    <div className={styles.timerText}>{this.state.timerText}</div>
                                </div>

                                {/*<div style={{ position: "absolute" }}>*/}
                                {/*    <button onClick={() => this.setState({ showPlayAgainButtons: !this.state.showPlayAgainButtons })}>showPlayAgainButtons</button>*/}
                                {/*    <button onClick={() => this.setState({ hideBonusRoundWinners: true })}>hide bonus winners</button>*/}
                                {/*    <button onClick={() => this.setState({ showRoundNumber: !this.state.showRoundNumber })}>ShowRoundNumber</button>*/}
                                {/*    <button onClick={() => { this.setState({ roundType: "standard" }); this.doStartRound({ roundNumber: 1, playerSelectingId: "1" }) }}>startRound</button>*/}
                                {/*    <button onClick={() => this.newLetter({ letter: "A", type: "vowel" })}>addVowel</button>*/}
                                {/*    <button onClick={() => this.doStartBonusRound()}>startBonusRound</button>*/}
                                {/*    <button onClick={() => this.doEndBonusAnswer()}>endBonusRound</button>*/}
                                {/*    <button onClick={() => this.setState({ bonusSubmitions: ["1"] })}>set 1 Player on podium</button>*/}
                                {/*    <button onClick={() => this.setState({ bonusSubmitions: ["1", "2"] })}>set 2 Player on podium</button>*/}
                                {/*    <button onClick={() => this.setState({ bonusSubmitions: ["1", "2", "3"] })}>set 3 Player on podium</button>*/}
                                {/*    <button onClick={() => this.setState({ showBonusAnagram: !this.state.showBonusAnagram })}>Show Bonus Answer</button>*/}
                                {/*    <button onClick={() => this.setState({ showInfoBox: !this.state.showInfoBox, infoBoxText: "This is a test Info Box" })}>Show Info Box</button>*/}
                                {/*    <button onClick={() => this.setState({ showWinners: !this.state.showWinners })}>Show winner</button>*/}
                                {/*</div>*/}
                            </React.Fragment>
                    }
                </div>
            </div>
        );
    }
}