import React, { Component } from 'react';
import Lottie from 'react-lottie';

import getAvatarById from "constants/avatars";
import styles from "components/WordWheel/PlayerStyles.module.scss";

import StarImg from "images/WordWheel/star.png";
import CrossImg from "images/WordWheel/cross.png";

export default class Player extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pointComponents: [],
            displayScore: 0,
        };
    }

    componentDidMount() {
        this.props.room.state.players[this.props.player.id].wordData.listen("score", (value, prevValue) => {
            if (value > 0) {
                let pointComponents = [...this.state.pointComponents];
                pointComponents.push(<div key={`${this.props.player.id}-scoreAdd-${value}`} className={styles.pointAdd}>+{value-prevValue}</div>);
                this.setState({ pointComponents });
                setTimeout(() => {
                    pointComponents = [...this.state.pointComponents];
                    pointComponents.shift();
                    this.setState({ pointComponents });
                }, 2000);
            }

            let displayScore = this.state.displayScore;
            let interval = setInterval(() => {
                if (displayScore < value) {
                    displayScore++;
                    this.setState({ displayScore });
                } else if (displayScore > value) {
                    displayScore--;
                    this.setState({ displayScore });
                } else {
                    clearInterval(interval);
                }
            }, 50);
        });
    }

    componentWillReceiveProps(nextProps) {

    }


    render() {
        return (
            <div id={`player-${this.props.player.id}`} className={`${styles.player} ${this.props.left ? "" : styles.reverse} ${this.props.player.showPlayer && styles.show}`}>
                <div id={`potato-${this.props.player.id}`} className={`${styles.potato}`}>
                    <div className={`${styles.nameAnchor} ${!this.props.left && styles.right}`} >
                        <div className={`${styles.name} ${!this.props.left && styles.right}`}>{this.props.player.name}</div>
                    </div>
                    <div className={`${styles.readyBox} ${this.props.player.wordData.ready && styles.show}`}>READY</div>
                    <Lottie
                        options={getAvatarById(this.props.player.avatar).idleAnim}
                        width="100%"
                        height="100%"
                        isClickToPauseDisabled={true}
                    />
                </div>
                <div className={`${styles.textSection}`}>
                    <div className={`${styles.answerBox} ${this.props.player.wordData.showAnswer && styles.show}`}>
                        <div className={styles.answerText}>{this.props.player.wordData.answer}</div>
                        <div className={`${styles.arrow} ${styles.bg}`} />
                        <div className={styles.arrow} />
                        <img src={this.props.player.wordData.isCorrect ? StarImg : CrossImg} className={`${styles.answerValidation} ${this.props.player.wordData.showValidation && styles.show}`} alt="answer validation" />
                    </div>
                    <div className={`${styles.pointsRow}`}>
                        <img src={StarImg} className={`${styles.star}`} alt="player star"/>
                        <div className={`${styles.points} `}>{this.state.displayScore}</div>
                    </div>
                    {
                        this.state.pointComponents
                    }
                </div>
            </div>
        )
    }
}