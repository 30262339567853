const animation = require("./hourglass.json");

module.exports = {
    loop: true,
    autoplay: true,
    prerender: true,
    renderer: "svg",
    animationData: animation,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid'
    },
}