import React, { Component } from 'react';
import Lottie from 'react-lottie';

import styles from 'components/LoadingStyles.module.scss';
import loadingAnim from 'animations/hourglass.js';

export default class Loading extends Component {

    constructor(props) {
        super(props);

        this.state = {
        };
    }

    render() {
        let loadingStyle;
        let path = window.location.pathname;
        if (path.includes("anagram-magic")) {
            loadingStyle = styles.anagram;
        } else if (path.includes("maths-master")) {
            loadingStyle = styles.maths;
        } else {
            loadingStyle = styles.word;
        }

        return (
            <div className={`${styles.loadingBG} ${loadingStyle} ${this.props.noBg ? styles.noBg : ""}`}>
                <div className={styles.animContainer}>
                    <Lottie options={loadingAnim} height="100%" width="100%" isClickToPauseDisabled={true} />
                </div>
                <div className={styles.text}>{this.props.loadingText}</div>
                {
                    this.props.progress >= 0 &&
                    <div className={styles.progressBar}>
                        <div className={styles.fill} style={{ width: `${this.props.progress}%` }}>
                        </div>
                        <span className={styles.label}>{Math.round(this.props.progress)}%</span>
                    </div>
                }
            </div>
        )
    }

}