import React, { Component } from 'react';
import * as Colyseus from "colyseus.js";
import Lottie from 'react-lottie';
import { Howl, Howler } from "howler";

import getAvatarById from "constants/avatars";

import "animate.css";
import styles from 'components/WordWheel/WordTutorialStyles.module.scss';

import StepThreeBubble from "images/WordWheel/tutorial-step-three-img.png";
import StepFourBubble from "images/WordWheel/tutorial-step-four-img.png";
import WheelBg from "images/WordWheel/wheel.png";

var audio = {

};

export default class Tutorial extends Component {
    static displayName = Tutorial.name;

    constructor(props) {
        super(props);

        this.client = new Colyseus.Client(process.env.REACT_APP_GAME_SERVER_URL);
        this.state = {
            showStepOne: true,
            showStepOneTextOne: false,
            showStepOneTextTwo: false,
            stepOnePlayers: [
                {
                    avatar: 2,
                    show: false,
                },
                {
                    avatar: 5,
                    show: false,
                },
                {
                    avatar: 8,
                    show: false,
                },
                {
                    avatar: 9,
                    show: false,
                }
            ],

            showStepTwo: false,
            showStepTwoText: false,
            stepTwoAnagram: "CIBLAEJUN",
            showStepTwoWheel: false,

            showStepThree: false,
            showStepThreeTextOne: false,
            showStepThreeTextTwo: false,
            stepThreeAnswers: [
                "LANE",
                "NEBULA",
                "UNCLE"
            ],
            showStepThreeAnswers: [],

            showStepFour: false,
            showStepFourText: false,
            showStepFourBubble: false,

            showStepFive: false,
            showStepFiveText: false,
        };
        this.snatcherAnimRef = React.createRef();

    }

    componentDidMount() {
        //this.initAudio();
        this.beginTutorial();
    }

    initAudio() {
        this.preloadAudio();
        Howler.volume(0.5);
    }

    preloadAudio() {
        for (let key in audio) {
            audio[key].loaded = new Howl({
                src: [audio[key].import],
                preload: true,
                loop: audio[key].loop,
                volume: audio[key].volume
            });
        }
    }

    playAudio(audio) {
        if (audio.loaded) audio.loaded.play();
    }

    getRandomElement(array) {
        return array[Math.random() * array.length >> 0];
    }

    beginTutorial() {
        setTimeout(() => {
            this.doStepOne();
        }, 2500);
    }

    sleep(time) {
        return new Promise((resolve) => setTimeout(resolve, time));
    }

    async doStepOne() {
        this.setState({ showStepOne: true, showStepOneTextOne: true, });
        await this.sleep(1000);

        let stepOnePlayers = [...this.state.stepOnePlayers];
        stepOnePlayers.forEach(async (x, i) => {
            await this.sleep(200);
            stepOnePlayers[i].show = true;
            this.setState({ stepOnePlayers, });
        });

        await this.sleep(2000);
        this.setState({ showStepOneTextTwo: true });

        await this.sleep(5000);
        this.setState({ showStepOne: false, });
        await this.sleep(1000);
        this.doStepTwo();
    }

    async doStepTwo() {
        this.setState({ showStepTwo: true, });
        await this.sleep(1000);
        this.setState({ showStepTwoText: true, });
        await this.sleep(1500);
        this.setState({ showStepTwoWheel: true });
        await this.sleep(5000);
        this.setState({ showStepTwo: false, });
        await this.sleep(1000);
        this.doStepThree();
    }

    async doStepThree() {
        this.setState({ showStepThree: true, });
        await this.sleep(1000);
        this.setState({ showStepThreeTextOne: true, });

        await this.sleep(1500);
        let showStepThreeAnswers = [...this.state.showStepThreeAnswers];
        for (let i = 0; i < this.state.stepThreeAnswers.length; i++) {
            await this.sleep(200);
            showStepThreeAnswers[i] = true;
            this.setState({ showStepThreeAnswers });
        }
        await this.sleep(1000);
        this.setState({ showStepThreeTextTwo: true, });
        await this.sleep(5000);
        this.setState({ showStepThree: false, });
        await this.sleep(1000);
        this.doStepFour();
    }

    async doStepFour() {
        this.setState({ showStepFour: true, });

        await this.sleep(1000);
        this.setState({ showStepFourText: true, });

        await this.sleep(5000);
        this.setState({ showStepFour: false, });
        await this.sleep(1000);
        this.doStepFive();
    }

    async doStepFive() {
        this.setState({ showStepFive: true, });
        await this.sleep(1000);
        this.setState({ showStepFiveText: true, });

        await this.sleep(5000);
        this.setState({ showStepFive: false, });
        this.props.room.send("end_tutorial", {});
    }

    getVoteCount() {
        let count = 0;
        this.props.players.forEach((x) => {
            if (x.votedSkip) count++;
        });
        return count;
    }

    render() {
        return (
            <div id="tutorialContainer" className={styles.tutorialContainer}>
                <div className={styles.voteCount}>Skip Votes: {this.getVoteCount()} / {this.props.players.length}</div>
                <div className={`${styles.stepOne} ${styles.step} ${this.state.showStepOne && styles.show}`}>
                    <div className={`${styles.text} ${this.state.showStepOneTextOne && styles.show}`}>Get ready...</div>
                    <div className={styles.playerRow}>
                        {
                            this.state.stepOnePlayers.map((x) => {
                                return <div className={`${styles.player} ${x.show && styles.show}`}>
                                    <Lottie
                                        options={getAvatarById(x.avatar).idleAnim}
                                        width="100%"
                                        height="100%"
                                        isClickToPauseDisabled={true}
                                    />
                                </div>
                            })
                        }
                    </div>
                    <div className={`${styles.text} ${this.state.showStepOneTextTwo && styles.show}`}>...It's time to play 'Word Wheel'</div>
                </div>
                <div className={`${styles.stepTwo} ${styles.step} ${this.state.showStepTwo && styles.show}`} style={{ width: "100%" }}>
                    <div className={`${styles.text} ${this.state.showStepTwoText && styles.show}`}>You will be shown a wheel of letters...</div>

                    <div className={`${styles.wheelSection} `}>
                        <div className={`${styles.wheel} ${this.state.showStepTwoWheel && styles.show}`} >
                            <img className={styles.wheelBg} src={WheelBg} alt="wheel-bg"/>
                            <div className={styles.wheelContainer}>
                                {
                                    this.state.stepTwoAnagram.split("", this.state.stepTwoAnagram.length).map((x, index) => {
                                        if (index === this.state.stepTwoAnagram.length - 1) {
                                            return <div className={styles.centerCircle}>{x}</div>
                                        } else {
                                            return <div className={styles.segment}>
                                                <div className={styles.segmentText}>{x}</div>
                                            </div>
                                        }
                                    })
                                }
                            </div>
                        </div>
                        {/*<div className={styles.bar}>*/}
                        {/*    <img className={styles.barFront} src={BarFront} />*/}
                        {/*    <div className={styles.barFillContainer}>*/}
                        {/*        <div className={styles.fill }></div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </div>
                </div>
                <div className={`${styles.stepThree} ${styles.step} ${this.state.showStepThree && styles.show}`}>
                    <div className={`${styles.text} ${this.state.showStepThreeTextOne && styles.show}`}>Find as many words as you can...</div>
                    <div className={styles.answerBoxRow}>
                        {
                            this.state.stepThreeAnswers.map((x, index) => {
                                return <div className={`${styles.answerBox} ${this.state.showStepThreeAnswers[index] && styles.show}`}>
                                    <div className={styles.answerText}>{x}</div>
                                    <div className={`${styles.arrow} ${styles.bg}`} />
                                    <div className={styles.arrow} />
                                </div>
                            })
                        }
                    </div>
                    <div className={`${styles.text} ${this.state.showStepThreeTextTwo && styles.show}`}>...using the middle letter each time!</div>
                </div>
                <div className={`${styles.stepFour} ${styles.step} ${this.state.showStepFour && styles.show}`}>
                    <div className={`${styles.text} ${this.state.showStepFourText && styles.show}`}>You get points for the length of each word!</div>
                </div>
                <div className={`${styles.stepFive} ${styles.step} ${this.state.showStepFive && styles.show}`}>
                    <div className={`${styles.text} ${styles.larger} ${this.state.showStepFiveText && styles.show}`}>GOOD LUCK!</div>
                </div>
            </div>
        );
    }
}