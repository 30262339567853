import React, { Component } from 'react';
import { getCookie } from './helpers/cookies';
import * as Sentry from "@sentry/react";
import { Route } from 'react-router-dom';
import { Layout } from './components/Layout';
import { AnagramHost } from './components/AnagramMagic/AnagramHost';
import { AnagramClient } from './components/AnagramMagic/AnagramClient';
import Loading from './components/Loading';

import { Helmet } from 'react-helmet';

import { MathsHost } from './components/MathsMaster/MathsHost';
import { MathsClient } from './components/MathsMaster/MathsClient';

import 'styles/Site.scss';
import { WordHost } from './components/WordWheel/WordHost';
import { WordClient } from './components/WordWheel/WordClient';

import constants from './constants/constants';

Sentry.init({
    dsn: "https://4f0e89e0ddbea4e1003600594c7584cd@o4507028880883712.ingest.us.sentry.io/4507492636622848",
    environment: process.env.REACT_APP_ENVIRONMENT ? process.env.REACT_APP_ENVIRONMENT : 'undefined',
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration({
            maskAllText: false,
            blockAllMedia: false,
        }),
    ],
    // Performance Monitoring
    tracesSampleRate: 0.2, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/scrawlweb-stage\.azurewebsites\.net/, /^https:\/\/scrawl\.game\.city/],
    // Session Replay
    replaysSessionSampleRate: 0.2, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

if (getCookie('uniqueId')) {
    Sentry.setUser({ id: getCookie('uniqueId') });
}

export default class App extends Component {
    static displayName = App.name;

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            percentage: 0,
        };
    }

    componentDidMount() {
        console.log(window.location.pathname, window.location.pathname.includes("maths-master"));
        let imagesToLoad = window.location.pathname.includes("anagram-magic") ? constants.anagramImages : window.location.pathname.includes("maths-master") ? constants.mathsImages : constants.wordImages;
        console.log(imagesToLoad);
        let percentage = this.state.percentage;
        let percentPerItem = 100 / (constants.fonts.length + imagesToLoad.length);

        // Preload images
        setTimeout(() => {
            Promise.all(imagesToLoad.map(image => {
                return new Promise((resolve, reject) => {
                    const img = new Image();
                    img.onload = resolve;
                    img.onerror = reject;
                    img.src = image;
                }).then(() => {
                    percentage += percentPerItem;
                    this.setState({ percentage });
                });
            })).then(() => {
                // Preload fonts
                return Promise.all(constants.fonts.map(font => {
                    return new Promise((resolve, reject) => {
                        const fontFace = new FontFace(font.font, `url(${font.path})`);
                        fontFace.load().then(() => {
                            document.fonts.add(fontFace);
                            percentage += percentPerItem;
                            this.setState({ percentage });
                            resolve();
                        }).catch(reject);
                    });
                }));
            }).then(() => {
                setTimeout(() => {
                    this.setState({ isLoading: false });
                }, 1500);
                console.log('Assets preloaded!');
            }).catch(error => {
                // if there's an error, we still want to show the app, so set isLoading to false
                this.setState({ isLoading: false });
                console.error('Error preloading resources:', error);
            });
        }, 1500);
    }

    render() {
        return (
            <Layout>
                <Helmet>
                    <meta name="format-detection" content="telephone=no" />
                </Helmet>
                {this.state.isLoading ?
                    <Loading loadingText={""} progress={this.state.percentage} />
                    :
                    <>
                        <Route exact path='/anagram-magic' component={AnagramHost} />
                        <Route path='/anagram-magic/client' component={AnagramClient} />


                        <Route exact path='/maths-master' component={MathsHost} />
                        <Route path='/maths-master/client' component={MathsClient} />

                        <Route exact path='/word-wheel' component={WordHost} />
                        <Route path='/word-wheel/client' component={WordClient} />
                    </>
                }
            </Layout>
        );
    }
}
